import React, { useEffect } from 'react';
import './style.css';
import { Snow } from 'src/screens/Dashboard/components/Snow';


const TreeLoader: React.FC = () => {
  useEffect(() => {
    // Event listener for 'Ok' button
    const handleOkClick = (e: Event) => {
      const target = e.target as HTMLElement;
      target.closest('.the-blood-coders__backdrop')?.remove();
    };

    const okButton = document.querySelector('.the-blood-coders__ok-btn');
    okButton?.addEventListener('click', handleOkClick);

    return () => {
      // Clean up the event listener on unmount
      okButton?.removeEventListener('click', handleOkClick);
    };
  }, []);

  return (
    <div>
      {/* Main Container */}
      <div className="container-c">
        <div className="tree">
          {Array.from({ length: 15 }).map((_, i) => (
            <div key={i}></div>
          ))}
        </div>
        <div className="star"></div>
        <div className="base"></div>
        <div className="shadow"></div>
      </div>

      <Snow/>

      {/* Small Stars */}
      {/* {[0, 10, 20, 30, 40, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((left, index) => (
        <div
          key={index}
          className="small-star"
          style={{
            '--left': `${left}%`,
            '--delay': `${Math.random() * 2}s`,
          } as React.CSSProperties} // Cast to React.CSSProperties to allow CSS custom properties
        ></div>
      ))} */}

      {/* Wish Title */}
      <h1 className="wish-title">
        {['F', 'e', 'l', 'i', 'z', '\u00A0\u00A0', 'N', 'a', 't', 'a', 'l'].map((letter, i) => (
          <span key={i} className="wish-letter">
            {letter}
          </span>
        ))}
      </h1>

    </div>
  );
};

export default TreeLoader;
