import React, { useState } from "react";
import { Container, Card, Button, Row, Col, Accordion } from "react-bootstrap";
import {
  FaFilePdf,
  FaFileContract,
  FaUserMinus,
  FaBuilding,
  FaCalendarCheck,
  FaChartBar,
  FaChevronDown,
  FaChevronRight,
} from "react-icons/fa";
import Vaga from '../../../assets/solicitacoes/orientacao_processo_seletivo_3.0.pdf';
import Contrato from '../../../assets/solicitacoes/carta_de_orientacao.pdf';
import Cartao from '../../../assets/solicitacoes/cartao-pre-cadastro.png';
import ProcessoSeletivo from '../../../assets/solicitacoes/Slide-para-o-processo-seletivo.pptx';
import Cartilha from '../../../assets/solicitacoes/Cartilha-financeira.pdf';
import { Link } from "react-router-dom";

import './style.css';

const buttonInfos = [
    /*{
        label: "Solicitar Vaga",
        description: (
            <>
                <p>Este campo permite que sua empresa manifeste interesse em candidatos para vagas de estágio disponíveis, contribuindo para o fortalecimento das equipes e complementando os currículos dos candidatos.</p>
                <p>Por favor, preencha as informações relevantes ao perfil desejado. Certifique-se de revisar todos os dados antes de enviar a solicitação. Nossa equipe entrará em contato para agendar entrevistas com os candidatos que melhor se alinhem às suas necessidades.</p>
                <p><strong>Fique atento às Regras do Processo Seletivo. Baixe o PDF com as orientações completas.</strong></p>
            </>
        ),
        variant: "primary",
        shadow: "var(--primary)",
        link: "https://form.jotform.com/drestagioof/solicitacao-de-vagas",
        pdfLink: Vaga,
        icon: <FaClipboardCheck />
    },
    */
    {
        label: "Solicitar contrato de estágio",
        description: (
            <>
                <p>Utilize este campo para que sua empresa solicite contratos de estágio, assegurando a formalização e regularização dos estagiários conforme as normas vigentes.</p>
                <p>Verifique todas as informações antes de enviar a solicitação. Nossa equipe entrará em contato para concluir o processo e fornecer o suporte necessário.</p>
                <p><strong>Fique atento às regras de contratação. Baixe o PDF com as orientações completas.</strong></p>
            </>
        ),
        variant: "success",
        shadow: "var(--green)",
        link: "https://form.jotform.com/241765434055659",
        pdfLink: Contrato,
        icon: <FaFileContract />
    },
    {
        label: "Solicitar desligamento de estágio",
        description: (
            <>
                <p>Utilize este campo para solicitar o desligamento formal de estagiários da sua empresa. Por favor, forneça as informações necessárias, como o nome do estagiário, data prevista para o desligamento, e o motivo do encerramento do estágio.</p>
                <p>Certifique-se de revisar todos os dados antes de enviar a solicitação. Nossa equipe processará o pedido e entrará em contato para confirmar os próximos passos.</p>
            </>
        ),
        variant: "danger",
        shadow: "var(--red)",
        link: "https://form.jotform.com/241766196179672",
        icon: <FaUserMinus />
    },
    {
        label: "Inclusão de CNPJ",
        description: (
            <>
                <p>Insira o CNPJ da sua empresa neste campo. O CNPJ é um identificador essencial para formalizar contratos, solicitações e outras operações relacionadas à sua empresa.</p>
                <p>Verifique se o número inserido está correto e atualizado antes de enviar.</p>
            </>
        ),
        variant: "warning",
        shadow: "var(--warning)",
        link: "https://form.jotform.com/233025932642654",
        icon: <FaBuilding />
    },
];

export const Solicitations: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<string | null>(null);
    const toggleAccordion = (index: string) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
      <Container fluid>
        <Card className="solicitations-home">
          <div className="text-center mb-4">
            <h1>Bem-vindo ao Estagius</h1>
            <p>
              Gerencie todas as solicitações e informações de estagiários
              diretamente pelo sistema.
            </p>
          </div>
  
          <Accordion>
            {buttonInfos.map((button, index) => (
              <Card key={index} className="mb-3" style={{ borderRadius: "10px" }}>
                <Accordion.Toggle
                  as="div" // Substituindo por div
                  eventKey={`${index}`}
                  className={`d-flex align-items-center justify-content-between border-${button.variant}`}
                  style={{
                    borderRadius: "10px",
                    cursor: "pointer",
                    fontSize: "1.2rem",
                    color: `var(--color-text)`,
                    boxShadow: `0 4px 0 ${button.shadow} !important`,
                    borderWidth: "2px",
                    borderStyle: "solid",
                    background: "var(--bg-secondary)",
                    padding: "15px", // Adicionando padding no div
                  }}
                  onClick={() => toggleAccordion(`${index}`)}
                >
                  <div className="d-flex align-items-center">
                    <div className="mr-3">{button.icon}</div>
                    <div>{button.label}</div>
                  </div>
                  <div className="d-flex align-items-center">
                  {button.link && (
                    <Button
                      variant={button.variant}
                      href={button.link}
                      target="_blank"
                      className="mr-2"
                      style={{
                        fontSize: "1rem",
                        padding: "6px 12px",
                        borderRadius: "5px",
                        color: "white",
                        border: "none",
                      }}
                    >
                      Acessar
                    </Button>
                  )}
                    {button.pdfLink && (
                      <Button
                        variant="secondary"
                        href={button.pdfLink}
                        target="_blank"
                        className="ml-2"
                        style={{
                          fontSize: "1rem",
                          padding: "6px 12px",
                          borderRadius: "5px",
                        }}
                      >
                        <FaFilePdf />
                      </Button>
                    )}
                    <div className="ml-3">
                      {activeIndex === `${index}` ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body>{button.description}</Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
  
          <Row className="mt-5 justify-content-center text-center">
            <h2>Material de Apoio</h2>
          </Row>
  
          <Accordion defaultActiveKey="0">
            <Card style={{ boxShadow: "none !important" }}>
              <Accordion.Toggle
                as="div" // Alterado para div
                eventKey="0"
                className="d-flex bg-secondary-c justify-content-between align-items-center mb-2"
                style={{
                  padding: "15px",
                  cursor: "pointer",
                  border: "solid 1px var(--slate-400)",
                  borderRadius: "var(--br-md)",
                }}
              >
                <h5 className="mb-0">Cartão Pré-cadastro</h5>
                <FaChevronDown />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    Deve ser entregue ao candidato que for selecionado para a vaga
                    de estágio. Com este folder, ele vai entrar em contato com a
                    nossa equipe.
                  </p>
                  <div className="d-flex justify-content-between">
                    <Button
                      style={{ width: "140px" }}
                      variant="primary"
                      href={Cartao}
                      target="_blank"
                    >
                      Ver Cartão
                    </Button>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
  
            <Card style={{ boxShadow: "none !important" }}>
              <Accordion.Toggle
                as="div" // Alterado para div
                eventKey="1"
                className="d-flex bg-secondary-c justify-content-between align-items-center mb-2"
                style={{
                  padding: "15px",
                  cursor: "pointer",
                  border: "solid 1px var(--slate-400)",
                  borderRadius: "var(--br-md)",
                }}
              >
                <h5 className="mb-0">Slide para o processo seletivo</h5>
                <FaChevronDown />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>Pode ser usado no momento da sua seleção de candidatos.</p>
                  <div className="d-flex justify-content-between">
                    <Button
                      style={{ width: "140px" }}
                      variant="primary"
                      href={ProcessoSeletivo}
                      target="_blank"
                    >
                      Ver Slide
                    </Button>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
  
            <Card style={{ boxShadow: "none !important" }}>
              <Accordion.Toggle
                as="div" // Alterado para div
                eventKey="2"
                className="d-flex bg-secondary-c justify-content-between align-items-center mb-2"
                style={{
                  padding: "15px",
                  cursor: "pointer",
                  border: "solid 1px var(--slate-400)",
                  borderRadius: "var(--br-md)",
                }}
              >
                <h5 className="mb-0">Cartilha Financeira</h5>
                <FaChevronDown />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>Tire suas principais dúvidas sobre questões financeiras.</p>
                  <div className="d-flex justify-content-between">
                    <Button
                      style={{ width: "140px" }}
                      variant="primary"
                      href={Cartilha}
                      target="_blank"
                    >
                      Ver Cartilha
                    </Button>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
  
          <Row className="mt-5">
            <Col lg={12} className="text-center">
              <h2>Acompanhe as Presenças dos Estagiários</h2>
              <p>
                O Estagius oferece uma funcionalidade completa para que sua
                empresa acompanhe de perto as presenças dos estagiários. Através
                do sistema, você pode visualizar o histórico de presenças,
                verificar datas específicas e conferir a quantidade de presenças e
                faltas de cada estagiário. Isso facilita a gestão e garante que
                todos os registros estejam atualizados e acessíveis a qualquer
                momento.
              </p>
            </Col>
          </Row>
  
          <Row className="mt-4">
            <Col lg={6}>
              <Card
                className="text-center hover-card h-100"
                style={{
                  borderRadius: "10px",
                  padding: "30px",
                  border: "2px solid var(--primary)",
                  background: "var(--bg-default)",
                  color: "black",
                  transition: "transform 0.3s",
                }}
              >
                <Card.Body style={{ borderRadius: "10px" }}>
                  <FaCalendarCheck
                    size={70}
                    className="mb-3"
                    style={{ opacity: 0.9, color: "var(--primary)" }}
                  />
                  <h5 className="mb-4">
                    Verifique as presenças detalhadas dos estagiários.
                  </h5>
                  <Button
                    variant="primary"
                    href="/relatorios/empresas/presencas"
                    style={{
                      fontSize: "1.2rem",
                      padding: "10px 20px",
                      borderRadius: "5px",
                    }}
                  >
                    Presenças
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card
                className="text-center hover-card h-100"
                style={{
                  borderRadius: "10px",
                  padding: "30px",
                  border: "2px solid #28a745",
                  background: "var(--bg-default)",
                  color: "black",
                  transition: "transform 0.3s",
                }}
              >
                <Card.Body style={{ borderRadius: "10px" }}>
                  <FaChartBar
                    size={70}
                    className="mb-3"
                    style={{ opacity: 0.9, color: "#28a745" }}
                  />
                  <h5 className="mb-4">
                    Acompanhe a contagem de presenças e faltas.
                  </h5>
                  <Button
                    variant="success"
                    href="/relatorios/empresas/contagem"
                    style={{
                      fontSize: "1.2rem",
                      padding: "10px 20px",
                      borderRadius: "5px",
                    }}
                  >
                    Contagem de presenças
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  };
