import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

// Importações do bootstrap
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Input from "../../../components/Input";

import schema from "./schema";
import api from "src/services/axios";

import { toast } from "react-toastify";

const GroupForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();

  const history = useHistory();

  const { handleSubmit, control, setValue, errors } = useForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (params.id) {
      api.get(`/groups/${params.id}`).then((response) => {
        setValue("name", response.data?.name);
      });
    }
  }, [params.id, setValue]);

  const onSubmit = (data) => {
    setLoading(true);
    const body = {
      name: data.name,
    };

    if (params.id) {
      api
        .put(`/groups/${params.id}`, body)
        .then(() => {
          history.goBack();
          toast.success("Atualizado com sucesso!");
        })
        .catch(() => {
          toast.error("Houve um erro ao atualizar o grupo!");
        });
    } else {
      api
        .post("/groups", body)
        .then(() => {
          history.goBack();
          toast.success("Cadastrado com sucesso!");
        })
        .catch((error) => {
          if (error.response?.data?.error?.message) {
            toast.error(error.response?.data?.error?.message);
            return;
          }
          toast.error("Houve um erro ao cadastrar o grupo!");
        });
    }
  };

  return (
    <Container fluid>
      <Card
        style={{
          background: "var(--bg-secondary)",
        }}
      >
        <Card.Header
          as="h5"
          style={{
            background: "transparent",
            border: "none",
          }}
        >
          Nova opção de grupo
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body
            style={{
              background: "var(--bg-default)",
              margin: " 0.5rem 1rem",
              borderRadius: "10px",
            }}
          >
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name="name"
                label="Nome do grupo"
                errorMessage={errors.name?.message}
                md="5"
                required
              />
            </Form.Row>
          </Card.Body>
          <Card.Footer className="bg-default">
            <Form.Row>
              <Button
                className="mr-3 button-primary"
                disabled={loading}
                type="submit"
                variant="success"
              >
                {loading ? "Carregando..." : "Salvar"}
              </Button>
              <Button onClick={() => history.goBack()} variant="danger">
                Cancelar
              </Button>
            </Form.Row>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
};

export default GroupForm;
