import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Container,
  Dropdown,
} from "react-bootstrap";
import {
  FaBars,
  FaSignOutAlt,
  FaMoon,
  FaSun,
  FaAdjust,
  FaArrowsAltV,
  FaArrowsAltH,
  FaArrowLeft,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { signOut } from "src/store/modules/auth/actions";
import "./style.css";
import { FaMinimize } from "react-icons/fa6";

const QuickActions = () => {
  const dispatch = useDispatch();
  const [areOtherActionsVisible, setAreOtherActionsVisible] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState("light");
  const [isSidebarVertical, setIsSidebarVertical] = useState(true);

  // Lista de temas atualizada
  const themes = ["dark", "light", "high-contrast"];

  // Aplicação e armazenamento de tema
  const applyTheme = useCallback(
    (themeKey) => {
      themes.forEach((theme) => {
        document.body.classList.remove(theme);
      });
      document.body.classList.add(themeKey);
      setSelectedTheme(themeKey);
      localStorage.setItem("selected-theme", themeKey);
    },
    [themes]
  );

  // Carregar tema salvo
  useEffect(() => {
    const savedTheme = localStorage.getItem("selected-theme") || "light";
    applyTheme(savedTheme);
  }, [applyTheme]);

  // Carregar posição do sidebar salva
  useEffect(() => {
    const savedSidebarPosition = localStorage.getItem("sidebar-position");
    setIsSidebarVertical(savedSidebarPosition !== "top-sidebar");
    if (savedSidebarPosition === "top-sidebar") {
      document.body.classList.add("top-sidebar");
    }
  }, []);

  // Alternar visibilidade de outras ações
  const handleToggleActionBar = () => {
    setAreOtherActionsVisible(!areOtherActionsVisible);
  };

  // Alternar e salvar a posição do sidebar
  const handleToggleSidebarPosition = () => {
    const newSidebarPosition = isSidebarVertical ? "top-sidebar" : "";
    if (isSidebarVertical) {
      document.body.classList.add("top-sidebar");
    } else {
      document.body.classList.remove("top-sidebar");
    }
    setIsSidebarVertical(!isSidebarVertical);
    localStorage.setItem("sidebar-position", newSidebarPosition);
  };

  // Renderizar ícone do tema
  const getThemeIcon = (themeKey) => {
    switch (themeKey) {
      case "dark":
        return <FaMoon />;
      case "light":
        return <FaSun />;
      case "high-contrast":
        return <FaAdjust />;
      default:
        return <FaSun />;
    }
  };

  return (
    <Container
      fluid
      className="quick-actions-bar d-flex justify-content-between"
    >
      {/* Botão de voltar à página anterior */}
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="go-back-tooltip">Voltar</Tooltip>}
      >
        <Button
          variant="link"
          onClick={() => window.history.back()}
          className="quick-action-btn"
        >
          <FaArrowLeft />
        </Button>
      </OverlayTrigger>

      {/* Botão de alternar posição do Menu */}
      {areOtherActionsVisible && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="toggle-menu-tooltip">Alternar menu</Tooltip>}
        >
          <Button
            variant="link"
            onClick={handleToggleSidebarPosition}
            className="quick-action-btn"
          >
            {isSidebarVertical ? <FaArrowsAltH /> : <FaArrowsAltV />}
          </Button>
        </OverlayTrigger>
      )}

      {/* Dropdown de Seleção de Temas */}
      {areOtherActionsVisible && (
        <Dropdown>
          <Dropdown.Toggle
            variant="link"
            id="dropdown-basic"
            className="quick-action-btn"
          >
            {getThemeIcon(selectedTheme)}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {themes.map((theme) => (
              <Dropdown.Item
                key={theme}
                onClick={() => applyTheme(theme)}
                active={selectedTheme === theme}
              >
                {theme === "dark" ? "Tema Escuro" : ""}
                {theme === "light" ? "Tema Padrão" : ""}
                {theme === "high-contrast" ? "Tema Alto Contraste" : ""}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}

      {/* Ocultar Barra de Ações */}
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="toggle-action-bar-tooltip">
            {areOtherActionsVisible
              ? "Minimizar Barra de Ações"
              : "Expandir Barra de Ações"}
          </Tooltip>
        }
      >
        <Button
          variant="link"
          onClick={handleToggleActionBar}
          className="quick-action-btn"
        >
          <FaMinimize />
        </Button>
      </OverlayTrigger>

      {/* Logout */}
      {areOtherActionsVisible && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="signout-tooltip">Sair</Tooltip>}
        >
          <Button
            variant="link"
            onClick={() => dispatch(signOut())}
            className="quick-action-btn"
          >
            <FaSignOutAlt />
          </Button>
        </OverlayTrigger>
      )}
    </Container>
  );
};

export default QuickActions;
