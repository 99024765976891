import React from "react";
import "./style.css";
export const NoDataMessage = () => {
  return (
    <>
      <div className="loader">
        <div className="load-inner load-one"></div>
        <div className="load-inner load-two"></div>
        <div className="load-inner load-three"></div>
        <span className="text">Sem dados</span>
      </div>
    </>
  );
};
