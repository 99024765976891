import React, { useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { handleGenerateReportAttendance } from "../../Relatorios/templates/report";

import ExportJsonExcel from "js-export-excel";

// Componentes do react-bootrap
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

// Componentes da aplicação
import Select from "../../../components/Select";
import InputDate from "../../../components/InputDate";
import ComplexTable from "../../../components/ComplexTable";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

import api from "src/services/axios";
import { toast } from "react-toastify";

const AttendanceReportCompany: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false); // Estado de carregamento

  const { handleSubmit, control, watch } = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {
      date_start: undefined,
      date_end: undefined,
      typeOptions: {
        value: "",
        label: "Tudo",
      },
      order: {
        value: "name",
        label: "Nome",
      },
    },
  });

  const order = watch("order");

  const OnSubmit = useCallback((data) => {
    setLoading(true); // Inicia o carregamento
    let queryParams = new URLSearchParams();

    if (!data.date_start && !data.date_end) {
      toast.error("Selecione uma faixa de datas");
      setLoading(false); // Para o carregamento em caso de erro
      return;
    }

    queryParams.set("date_start", format(data.date_start, "yyyy-MM-dd"));
    queryParams.set("date_end", format(data.date_end, "yyyy-MM-dd"));
    queryParams.set("type", data.typeOptions.value);

    api
      .get(`/reports/companies/attendances?${queryParams}`)
      .then((response) => {
        setData(
          response.data.map((item) => {
            return {
              ...item,
              trainee_name: item.name,
              date: format(parseISO(item.date.substring(0, 10)), "dd-MM-yyyy"),
            };
          })
        );
      })
      .finally(() => {
        setLoading(false); // Finaliza o carregamento, independentemente de sucesso ou erro
      });
  }, []);

  const handleGenerateExcelFile = () => {
    const parseData = data.map((item) => {
      return {
        name: item.name,
        company_name: item.company_name,
        cpf: item.cpf,
        date: item.date,
        primary_phone_contact: item.primary_phone_contact,
        type: item.type,
      };
    });

    let option = {
      fileName: `${Date.now()}-presencas`,
      datas: [
        {
          sheetData: parseData,
          sheetName: "sheet",
          sheetFilter: [
            "name",
            "company_name",
            "cpf",
            "date",
            "primary_phone_contact",
            "type",
          ],
          sheetHeader: ["Nome", "Empresa", "Cpf", "Data", "Contato", "Tipo"],
        },
      ],
    };

    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  return (
    <Container fluid>
      <Card>
        <Card.Header as="h5">Presenças de seus alunos</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(OnSubmit)}>
            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name="date_start"
                label="Data inicial"
                md="6"
              />
              <Controller
                as={InputDate}
                control={control}
                name="date_end"
                label="Data final"
                md="6"
              />
            </Form.Row>

            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="order"
                label="Qual a ordenação ?"
                md="12"
                options={[
                  {
                    value: "name",
                    label: "Nome do aluno",
                  },
                  {
                    value: "date",
                    label: "Data da presença",
                  },
                ]}
              />

              <Controller
                as={Select}
                control={control}
                name="typeOptions"
                label="Presença/Faltas"
                md="6"
                options={[
                  {
                    value: "",
                    label: "Tudo",
                  },
                  {
                    value: "PRESENÇA",
                    label: "Apenas presenças",
                  },
                  {
                    value: "FALTA",
                    label: "Apenas faltas",
                  },
                ]}
              />
            </Form.Row>
            <div className="d-flex justify-content-between">
              <Button
                variant="primary"
                onClick={() => {}}
                type="submit"
                disabled={loading} // Desabilita o botão durante o carregamento
              >
                {loading ? "Buscando..." : "Filtrar"}
              </Button>
              <div className="d-flex" style={{ gap: "10px" }}>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() =>
                    handleGenerateReportAttendance(data, "", order)
                  }
                  disabled={loading} // Desabilita durante o carregamento
                >
                  Gerar PDF
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => handleGenerateExcelFile()}
                  disabled={loading} // Desabilita durante o carregamento
                >
                  Gerar Excel
                </Button>
              </div>
            </div>
          </Form>

          {loading ? (
            <LoadingIndicator /> // Exibe o indicador de carregamento durante o loading
          ) : (
            <ComplexTable
              data={data}
              columns={[
                {
                  dataField: "status",
                  text: "Status do contrato",
                  sort: true,
                  filter: false,
                  formatter: (_, row) => (
                    <div>{row.status ? "ATIVO" : "INATIVO"}</div>
                  ),
                },
                {
                  dataField: "trainee_name",
                  text: "Estagiário",
                  sort: true,
                  filter: false,
                  formatter: (_, row) => (
                    <div
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      <Link
                        to={{
                          pathname: `/relatorios/empresas/documentos/${row.id}`,
                        }}
                      >
                        {row.trainee_name}
                      </Link>
                    </div>
                  ),
                },
                {
                  dataField: "primary_phone_contact",
                  text: "Telefone",
                  sort: true,
                  filter: false,
                },
                {
                  dataField: "company_name",
                  text: "Empresa",
                  sort: false,
                  filter: false,
                },
                {
                  dataField: "date",
                  text: "Data da presença/falta",
                  sort: false,
                  filter: false,
                },
                {
                  dataField: "type",
                  text: "Presença/Falta",
                  sort: false,
                  filter: false,
                },
              ]}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AttendanceReportCompany;
