import React, { useState, useEffect } from "react";
import {
  Container,
  ProgressBar,
  Figure,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import Bus from "../../../assets/Ilustrations/bus.png"; // Caminho para a imagem do ônibus
import Progress1000 from "../../../assets/Ilustrations/progress-1000.svg";
import Progress950 from "../../../assets/Ilustrations/progress-950.svg";
import Progress900 from "../../../assets/Ilustrations/progress-900.svg";
import Progress850 from "../../../assets/Ilustrations/progress-850.svg";
import Progress800 from "../../../assets/Ilustrations/progress-800.svg";
import Progress750 from "../../../assets/Ilustrations/progress-750.svg";
import rocha750 from "../../../assets/Ilustrations/rock-750.png";
import rocha800 from "../../../assets/Ilustrations/rock-800.png";
import rocha850 from "../../../assets/Ilustrations/rock-850.png";
import rocha900 from "../../../assets/Ilustrations/rock-900.png";
import rocha950 from "../../../assets/Ilustrations/rock-950.png";
import Meta from "../../../assets/Images/Meta.png"; // Meta Image for stage 1000
import Pickaxe from "../../../assets/Ilustrations/Pickaxe.png"; // Picareta
import "../css/ProgressPanel.css"; // CSS adicional
import { Snow } from "./Snow";

interface ProgressPanelProps {
  totalContracts: number;
}

// Lista de estágios com imagens e mensagens associadas
const stages = [
  {
    value: 750,
    image: Progress750,
    message: "Inspiração - Vamos que vamos!",
    obstacle: { img: rocha750 },
  },
  {
    value: 800,
    image: Progress800,
    message: "Objetivo - Que garra, parabéns!",
    obstacle: { img: rocha800 },
  },
  {
    value: 850,
    image: Progress850,
    message: "Foco - Mantenha o foco!",
    obstacle: { img: rocha850 },
  },
  {
    value: 900,
    image: Progress900,
    message: "Determinação - Falta pouco!",
    obstacle: { img: rocha900 },
  },
  {
    value: 950,
    image: Progress950,
    message: "Visão - Quase lá!",
    obstacle: { img: rocha950 },
  },
  {
    value: 1000,
    image: Progress1000,
    message: "Meta Alcançada!",
    obstacle: { img: Meta },
  }, // Usar Meta como obstáculo
];

const ProgressPanel: React.FC<ProgressPanelProps> = ({ totalContracts }) => {
  // ======== { Estados Iniciais } ========
  // Estados para controlar o progresso, posição do ônibus, pedras quebradas, estágio atual, etc.
  const [displayedContracts, setDisplayedContracts] = useState(totalContracts); // Contratos exibidos no painel
  const [busPosition, setBusPosition] = useState(0); // Posição do ônibus
  const [brokenObstacles, setBrokenObstacles] = useState<number[]>(() => {
    const savedObstacles = localStorage.getItem("brokenObstacles");
    return savedObstacles ? JSON.parse(savedObstacles) : [];
  }); // Pedras quebradas salvas no localStorage
  const [isExploding, setIsExploding] = useState<number | null>(null); // Indica o estágio de explosão atual
  const [showPickaxe, setShowPickaxe] = useState(true); // Mostra ou esconde a picareta
  const [celebration, setCelebration] = useState<boolean>(false); // Controla a celebração quando a meta é atingida
  const [currentStage, setCurrentStage] = useState(stages[0]); // Estágio atual baseado no progresso

  // ======== { Validação de Contratos } ========
  // Garante que o número de contratos não ultrapasse os valores de 750 e 1000
  const validContracts = Math.max(750, Math.min(totalContracts, 1000));
  const percentage = Math.min(100, ((validContracts - 750) / 250) * 100); // Calcula a porcentagem do progresso

  // ======== { Lógica de Animação de Contratos } ========
  // Anima o número de contratos de forma progressiva até o valor de `validContracts`
  useEffect(() => {
    let start = 750; // Inicia o contador em 750
    const end = validContracts; // Usa o valor atualizado de `totalContracts`
    const duration = 3000; // Duração da animação (3 segundos)
    const increment = (end - start) / (duration / 50); // Incremento gradual

    const animateCount = () => {
      start += increment;
      if (start < end) {
        setDisplayedContracts(Math.floor(start)); // Atualiza `displayedContracts` progressivamente
        setTimeout(animateCount, 50); // Continua a animação até atingir o valor final
      } else {
        setDisplayedContracts(end); // Quando a animação termina, define `displayedContracts` como o valor final
      }
    };

    animateCount(); // Inicia a animação
  }, [validContracts]); // A animação é reiniciada quando `validContracts` muda

  // ======== { Atualiza o Estágio Atual } ========
  // Atualiza o estágio atual com base no número de contratos válidos
  useEffect(() => {
    const updatedStage =
      stages.find((stage) => validContracts >= stage.value) || stages[0];
    setCurrentStage(updatedStage); // Atualiza o estágio com base nos contratos válidos
  }, [validContracts]); // O estágio é atualizado quando `validContracts` muda

  // ======== { Ajusta a Posição do Ônibus } ========
  // Ajusta a posição do ônibus com base na última pedra quebrada
  useEffect(() => {
    if (brokenObstacles.length > 0) {
      const lastBrokenStage = brokenObstacles[brokenObstacles.length - 1]; // Última pedra quebrada
      const lastBrokenStagePosition = ((lastBrokenStage - 750) / 250) * 100; // Calcula a posição do ônibus
      setBusPosition(lastBrokenStagePosition); // Atualiza a posição do ônibus
    }
  }, [brokenObstacles]); // Atualiza a posição sempre que `brokenObstacles` mudar

  // ======== { Lógica de Destruição de Obstáculos } ========
  // Lida com a lógica de quebrar os obstáculos com a picareta
  const handleDestroyObstacle = (stageValue: number) => {
    if (
      validContracts >= stageValue &&
      brokenObstacles.length ===
        stages.findIndex((stage) => stage.value === stageValue)
    ) {
      setIsExploding(stageValue); // Ativa a explosão no estágio correto
      setShowPickaxe(false); // Oculta a picareta temporariamente
      setTimeout(() => {
        setBrokenObstacles([...brokenObstacles, stageValue]); // Adiciona o estágio quebrado ao array
        localStorage.setItem(
          "brokenObstacles",
          JSON.stringify([...brokenObstacles, stageValue])
        ); // Salva no localStorage
        setIsExploding(null); // Remove o estado de explosão
        setShowPickaxe(true); // Exibe a picareta para o próximo obstáculo
        if (stageValue === 1000) {
          setCelebration(true); // Inicia a celebração se a meta foi atingida
        }
      }, 1000); // Duração da animação de explosão
    }
  };

  // ======== { Geração de Confetes para Celebração } ========
  const generateConfetti = () => {
    const confettiElements: JSX.Element[] = [];
    for (let i = 0; i < 100; i++) {
      confettiElements.push(
        <div
          key={i}
          className="confetti"
          style={generateConfettiStyles()}
        ></div>
      );
    }
    return confettiElements;
  };

  useEffect(() => {
    // Se validContracts for 1000, ativa a celebração continuamente
    if (validContracts === 1000) {
      setCelebration(true);
    } else {
      setCelebration(false); // Desativa a celebração se a meta não for 1000
    }
  }, [validContracts]);

  // ======== { Estilos para os Confetes } ========
  const generateConfettiStyles = (): React.CSSProperties => {
    const colors = ["#FFC700", "#FF0000", "#2E8B57", "#1E90FF", "#FF69B4"];
    const size = Math.random() * 10 + 5; // Tamanho aleatório
    const left = Math.random() * 100; // Posição horizontal aleatória
    const duration = Math.random() * 3 + 2; // Duração aleatória

    return {
      backgroundColor: colors[Math.floor(Math.random() * colors.length)],
      width: `${size}px`,
      height: `${size}px`,
      left: `${left}%`,
      animationDuration: `${duration}s`,
      position: "absolute",
      top: "-10%",
    };
  };

  // ======== { Atualização do Estágio com Base no Total de Contratos } ========
  // Itera pelos estágios e encontra o mais próximo sem ultrapassar o valor de totalContracts
  useEffect(() => {
    const updatedStage = stages.reduce((closestStage, currentStage) => {
      return validContracts >= currentStage.value ? currentStage : closestStage;
    }, stages[0]);

    setCurrentStage(updatedStage); // Atualiza o estado com o estágio correto
  }, [validContracts]); // Atualiza sempre que validContracts mudar

  return (
    <Container>


      <div className="progress-panel">
        <Snow />
        <div className="progress-meta">
          <h2 className="progress-title">Progresso da Meta</h2>
          <div className="progress-value">{displayedContracts} / 1000</div>
        </div>

        <div className="stage-content">
          <Figure>
            <Figure.Caption>{currentStage.message}</Figure.Caption>
            <Figure.Image
              src={currentStage.image}
              alt={`Imagem da etapa ${currentStage.value}`}
              className="stage-image"
            />
          </Figure>
        </div>

        {celebration && (
          <>
            <div className="confetti-container">{generateConfetti()}</div>
            <div className="celebrate-message">Meta Atingida! Parabéns!</div>
          </>
        )}

        <div className="progress-bar-container fade-animation">
          <ProgressBar
            now={percentage}
            className="progress-bar"
            animated
            color="blue"
          />

          <div className="bus" style={{ left: `${busPosition}%` }}>
            <img src={Bus} alt="Ônibus" />
          </div>

          {stages.map((stage, index) => {
            const isNextToBreak = brokenObstacles.length === index;
            const canBreak = validContracts >= stage.value;
            return (
              <div
                key={stage.value}
                className="obstacle-stage"
                style={{ left: `${((stage.value - 750) / 250) * 100}%` }}
              >
                <img
                  src={stage.obstacle.img}
                  alt={`Obstacle Stage ${stage.value}`}
                  className={`obstacle-image ${
                    brokenObstacles.includes(stage.value) ? "broken" : ""
                  } ${isExploding === stage.value ? "exploding" : ""}`}
                />
                <div className="stage-marker">{stage.value}</div>
                {isNextToBreak &&
                  !brokenObstacles.includes(stage.value) &&
                  canBreak &&
                  showPickaxe && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${stage.value}`}>
                          Quebrar obstáculo
                        </Tooltip>
                      }
                    >
                      <div
                        className="pickaxe"
                        onClick={() => handleDestroyObstacle(stage.value)}
                      >
                        <img src={Pickaxe} alt="Picareta" />
                      </div>
                    </OverlayTrigger>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default ProgressPanel;
