import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Logo from "../../assets/Images/Logo.svg";
import './style.css'
export const Header = () => {
  return (

    <>
      <header className="header" id="header">
        <div className="header__container">
       
          <Link to="/" className="header__logo filter-brightness">
            <Image src={Logo} />
          </Link>
        </div>
      </header>
    </>
  )
}