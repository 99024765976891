import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { format, parseISO } from 'date-fns';

import { handleGenerateReportInactivesContract } from '../templates/report'

// Componentes do react-bootrap
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form';

import ExportJsonExcel from 'js-export-excel'


// Componentes da aplicação
import Select from '../../../components/Select';
import InputDate from '../../../components/InputDate'
import ComplexTable from '../../../components/ComplexTable';

import api from 'src/services/axios';
import { FaFilePdf, FaFilter } from 'react-icons/fa';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

const InactivesContractsReport: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<any>([]);
  const [companiesOptions, setCompaniesOptions] = useState([{
    value: '',
    label: 'Todas'
  }]);


  const { handleSubmit, control, watch } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      company_id: [{
        value: '',
        label: 'Todas'
      }],
      date_start: undefined,
      date_end: undefined,
      omitir_value: {
        value: false,
        label: 'Não'
      }
    }
  });

  const omitir_value = watch('omitir_value');

  const handleSetReport = useCallback((data) => {
    setContracts(data.map(item => {
      return {
        id: item.id,
        trainee_name: item.trainee?.name,
        trainee_primary_phone_contact: item.trainee?.primary_phone_contact,
        company_name: item?.company?.company_name,
        insurance_number: item.insurance_number,
        status: item?.status,
        trainee_date_birth: item.trainee.date_birth ? format(parseISO(item.trainee.date_birth), 'dd/MM/yyyy') : '',
        total_value: item.internship_scholarship_value + item.transportation_assistance_value,
        date_shutdown: format(parseISO(item.date_shutdown), 'dd/MM/yyyy'),
        integration_agent_value_company: item.integration_agent_value_company,
        institution_value_company: item.institution_value_company,
        reason_shutdown: item.reason_shutdown
      }
    }));
  }, []);


  const OnSubmit = useCallback((data) => {
    setLoading(true)
    let queryParams = new URLSearchParams();

    if (data.date_start && data.date_end) {
      queryParams.set('date_start', format(data.date_start, 'yyyy-MM-dd'));
      queryParams.set('date_end', format(data.date_end, 'yyyy-MM-dd'));
    }

    if (data.company_id) {
      queryParams.set('company_id', data.company_id.reduce((acc, current) => {
        return [...acc, current.value]
      }, []));

    }


    api.get(`reports/inactives?${queryParams}`).then(response => {
      handleSetReport(response.data);
    })
      .finally(() => setLoading(false))

  }, [handleSetReport]);



  const handleGenerateExcelFile = () => {

    const parseContracts = contracts.map(item => {
      return {
        name: item.trainee_name,
        trainee_date_birth: item.trainee_date_birth,
        contact: item.trainee_primary_phone_contact,
        company_name: item.company_name,
        date_shutdown: item.date_shutdown,
        reason_shutdown: item.reason_shutdown
      }
    })

    let option = {
      fileName: `${Date.now()}-desligados`,
      datas: [
        {
          sheetData: parseContracts,
          sheetName: 'sheet',
          sheetFilter: ['name', 'trainee_date_birth', 'contact', 'company_name', 'date_shutdown', 'reason_shutdown'],
          sheetHeader: ['Nome', 'Nascimento', 'Contato', 'Empresa', 'Data de desligamento', 'Motivo'],
        }
      ]
    }


    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  useEffect(() => {
    api.get('companies').then((response) => {
      setCompaniesOptions([...companiesOptions, ...response.data.map(item => {
        return {
          value: item.id,
          label: item.company_name,
        }
      })]);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Card style={{  }}>
        <Card.Header as="h5" className='color-secondary' >
          Relatórios de contratos desligados
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(OnSubmit)}>
            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name="date_start"
                label="Data inicial"
                md="6"
              />
              <Controller
                as={InputDate}
                control={control}
                name="date_end"
                label="Data final"
                md="6"
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="company_id"
                label="Qual a empresa ?"
                md="6"
                options={companiesOptions}
                isMulti
              />
              <Controller
                as={Select}
                control={control}
                name="omitir_value"
                label="Omitir coluna de valores ?"
                md="6"
                options={[
                  {
                    value: true,
                    label: 'Sim'
                  },
                  {
                    value: false,
                    label: 'Não'
                  }
                ]}
              />
            </Form.Row>

            <div className='d-flex justify-content-between mb-4'>
              <Button
                onClick={() => { }}
                className="button-primary"
                type="submit"
              >
                <FaFilter className='mr-2' />
                {loading ? 'Buscando...' : 'Filtrar'}
              </Button>
              <div>
                <Button
                  type="button"
                  variant='secondary'
                  onClick={() => handleGenerateReportInactivesContract(contracts, 'Relatório de contratos desligados', omitir_value)}
                  className="button-secondary-outline mr-3"
                >
                  <FaFilePdf className='mr-2' size={20} />
                  Gerar PDF
                </Button>
                <Button
                  type="button"
                  variant='secondary'
                  onClick={() => handleGenerateExcelFile()}
                  className="button-secondary "
                >
                  <RiFileExcel2Fill className='mr-2' />
                  Gerar Excel
                </Button>
              </div>
            </div>
          </Form>
          {loading ? (
            <LoadingIndicator /> // Mostra o LoadingIndicator durante o carregamento
          ) : (
            <ComplexTable
              data={contracts}
              columns={[
                {
                  dataField: 'trainee_name',
                  text: 'Estagiário',
                  sort: false,
                  filter: false,
                },
                {
                  dataField: 'trainee_primary_phone_contact',
                  text: 'Contato',
                  sort: false,
                  filter: false,
                },
                {
                  dataField: 'company_name',
                  text: 'Empresa',
                  sort: false,
                  filter: false,
                },

                {
                  dataField: 'date_shutdown',
                  text: 'Data do desligamento',
                  sort: false,
                  filter: false,
                },
                {
                  dataField: 'status',
                  text: 'Status',
                  sort: false,
                  filter: false,
                  formatter: (_, row) => (
                    <>
                      {
                        row.status ? (
                          <Badge pill variant="success">
                            ATIVO
                          </Badge>) : (
                          <Badge pill variant="danger">
                            INATIVO
                          </Badge>
                        )
                      }
                    </>
                  )
                },
              ]}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default InactivesContractsReport;
