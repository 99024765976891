import React from 'react';

import {
  FaUserPlus, // Substituto para Adicionar Supervisor
  FaHandshake, // Substituto para Termo de Convênio
  FaFileSignature, // Substituto para Anexo - Termo de Convênio
  FaFolderOpen, // Substituto para Documentos da Empresa
  FaKey, // Substituto para Disponibilizar Credenciais
  FaEdit,
  FaEllipsisV,
  FaTrash,
  FaRedo, // Substituto para Renovar Contrato
  FaUserSlash, // Para Desligamento
  FaFileContract
} from 'react-icons/fa';
import { FaUserTie, FaFileCirclePlus } from 'react-icons/fa6';

interface IconProps {
  icon: string;
  color?: string;
  size?: number;
  onClick?(): any;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ icon, size = 25, ...rest }) => {
  // Define cores específicas para cada ícone
  const iconColors = {
    FaUserPlus: '#333',
    FaHandshake: '#333',
    FaFileSignature: '#333',
    FaFolderOpen: '#333',
    FaKey: '#333',
    FaEdit: 'var(--secondary-500)',
    FaEllipsisV: 'var(--secondary-400)',
    FaTrash: 'var(--red-regular)',
    FaRedo: '#333',
    FaUserSlash: 'var(--red-regular)',
    FaUserTie: '#333',
    FaFileContract: '#333',
    FaFileCirclePlus: 'var(--color-text)',
  };

  const icons = new Map([
    ['FaUserPlus', <FaUserPlus color={iconColors.FaUserPlus} size={size} {...rest} />],
    ['FaHandshake', <FaHandshake color={iconColors.FaHandshake} size={size} {...rest} />],
    ['FaFileSignature', <FaFileSignature color={iconColors.FaFileSignature} size={size} {...rest} />],
    ['FaFolderOpen', <FaFolderOpen color={iconColors.FaFolderOpen} size={size} {...rest} />],
    ['FaKey', <FaKey color={iconColors.FaKey} size={size} {...rest} />],
    ['FaEdit', <FaEdit color={iconColors.FaEdit} size={size} {...rest} />],
    ['FaEllipsisV', <FaEllipsisV color={iconColors.FaEllipsisV} size={size} {...rest} />],
    ['FaTrash', <FaTrash color={iconColors.FaTrash} size={size} {...rest} />],
    ['FaRedo', <FaRedo color={iconColors.FaRedo} size={size} {...rest} />],
    ['FaUserSlash', <FaUserSlash color={iconColors.FaUserSlash} size={size} {...rest} />],
    ['FaUserTie', <FaUserTie color={iconColors.FaUserTie} size={size} {...rest} />],
    ['FaFileContract', <FaFileContract color={iconColors.FaFileContract} size={size} {...rest} />],
    ['FaFileCirclePlus', <FaFileCirclePlus color={iconColors.FaFileCirclePlus} size={size} {...rest} />],

  ]);

  return <>{icons.has(icon) ? icons.get(icon) : null}</>;
};

export default Icon;
