/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Image } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import { useHistory } from 'react-router-dom';

import { signInRequest } from 'src/store/modules/auth/actions';
import LogoDrEstagio from '../../assets/Images/Logo.svg';
import './style.css';

const Login: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory()
  
  const dispatch = useDispatch();

   const { handleSubmit, control } = useForm({
    reValidateMode: 'onSubmit', 
    defaultValues: {
      cpf: '',     
      password: '',
    }
  });
  const onSubmit = useCallback(
    async (data: { cpf: string; password: string }) => {
      try {
        setLoading(true);
      
        await dispatch(signInRequest(data.cpf, data.password));
      } catch (error) {
        
        console.error('Erro no login:', error);
      } finally {
        
        setLoading(false);
      }
    },
    [dispatch] 
  );

  return (
    <>
      <section className="h-100vh w-full login-bg">
        <div className="login__container">
          <div className="login__content">
         
            <Form onSubmit={handleSubmit(onSubmit)} className="login__form">

              <Image className="text-center login__img" src={LogoDrEstagio} />
          
             
              <div>
                <h1 className="login__title">
                  <span>Bem vindo</span> ao Estagius.
                </h1>
                <p className="login__description">
                  Faça o login para continuar.
                </p>
              </div>

            
              <div className="login__inputs">
               
                <div>
                  <Controller
                    as={InputMask}
                    control={control}
                    mask="cpf"
                    name="cpf"
                    label="CPF"
                    className="login__input"
                    md="12"
                    required
                  />
                </div>

            
                <div className="login__box">
                  <Controller
                    as={Input}
                    control={control}
                    type="password"
                    name="password"
                    label="Senha"
                    className="login__input"
                    md="12"
                    required
                  />
                </div>
              </div>

              <div className="login__buttons">
                <Button
                  className="login__button"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Carregando..." : "Logar"} 
                </Button>


                <Button
                  className="login__button ml-4"
                  onClick={() => history.push('/parceiros')}
                  type="submit"
                >
                  Login para empresas 
                </Button>

              </div>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
