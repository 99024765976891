import React, { useState, useEffect, useCallback } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Swal from 'sweetalert2';
import ptBr from 'date-fns/locale/pt-BR';

import { useForm, Controller } from 'react-hook-form';

import Form from 'react-bootstrap/Form';

import { HiDocumentMinus } from "react-icons/hi2";
import { HiDocument, HiDocumentDuplicate, HiDocumentText } from "react-icons/hi";
import { FaFilter } from "react-icons/fa6";
import ComplexTable from '../../../components/ComplexTable';
import api from 'src/services/axios';
import { handleGenerateContrato, handleGenerateTerm, handleGenerateContratoTrainee } from './documents'
import Icon from '../../../components/icons'
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Select from '../../../components/Select';
import { handleGenerateTraineeRenovacao } from 'src/pdfs/aditivoRenovacao';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { Dropdown } from 'react-bootstrap';

export const ActionFormatter = ({
  row,
  handleGenerateContrato,
  handleGenerateContratoTrainee,
  handleGenerateTraineeRenovacao,
  handleGenerateTerm,
  handleReactiveContract
}) => {
  const history = useHistory();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Dropdown className='mr-4'>
        <Dropdown.Toggle variant="link" className="p-0">
          <Icon icon="FaEllipsisV" size={20} />
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight>
          <Dropdown.Item as="div" onClick={() => handleGenerateContrato(row)}>
            <HiDocument size={20} className="mr-2" /> Termo de Contrato Escola
          </Dropdown.Item>
          <Dropdown.Item as="div" onClick={() => handleGenerateContratoTrainee(row)}>
            <HiDocumentDuplicate size={20} className="mr-2" /> Termo de Contrato Estágio
          </Dropdown.Item>
          <Dropdown.Item as="div" onClick={() => handleGenerateTraineeRenovacao(row)}>
            <HiDocumentText size={20} className="mr-2" /> Termo de Quitação
          </Dropdown.Item>
          {!row.status && (
            <Dropdown.Item as="div" onClick={() => handleGenerateTerm(row)}>
              <HiDocumentMinus size={20} className="mr-2" /> Termo de Desligamento
            </Dropdown.Item>
          )}
          {!row.status && (
            <Dropdown.Item as="div" onClick={() => handleReactiveContract(row)}>
              <Icon icon="FaRedo" size={20} className="mr-2" /> Reativar Contrato
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      {/* Ícone separado para outras ações */}
      <Icon icon="FaUserSlash" size={25} onClick={() => history.push(`/desligamentos/${row.id}`, { data: row })} className="mr-4 color-secondary interaction" />
      <Icon icon="FaEdit" size={25} onClick={() => history.push(`/contratos/${row.id}`)} className="mr-4 color-secondary interaction" />

      {row.status && (
        <Icon icon="FaFileContract" size={25} onClick={() => history.push(`/contratos/renovacao/${row.id}`, { data: row })} className="mr-4 color-secondary interaction" />
      )}
    </div>
  );
};

const ContractsList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const [contracts, setContracts] = useState([]);

  const [traineeOptions, setTraineeOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState<any>([]);

  const { control, getValues } = useForm<any>({
    defaultValues: {
      company_id: null,
      trainee: null,
      status: {
        value: '',
        label: 'Todos status'
      },
    }
  });



  const handleExportClick = () => {
    api.get('contracts/export', { responseType: 'blob' })
      .then(response => {
        // Cria um URL temporário para o arquivo CSV
        const url = URL.createObjectURL(response.data);

        // Cria um link de download para o arquivo CSV
        const link = document.createElement('a');
        link.href = url;
        link.download = 'contratos.csv';
        link.click();

        // Libera o URL temporário
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Erro ao exportar CSV:', error);
      });
  };

  const handleGetContract = useCallback(() => {
    setLoading(true)
    const data = getValues();

    let params = `?status=${data.status?.value === undefined ? '' : data.status.value}`;

    if (data.company_id) {
      params = `${params}&company_id=${data.company_id.value}`
    }

    if (data.trainee) {
      params = `${params}&trainee_id=${data.trainee.value}`
    }


    api.get(`contracts${params}`).then((response) => {


      setContracts(response.data.map(item => {

        return {
          id: item.id,
          age: item?.trainee?.age,
          trainee_name: item.trainee?.name,



          company_name: item?.company?.company_name?.toUpperCase(),
          status: item?.status,
          course: item?.course.name?.toUpperCase(),
          date_shutdown: item?.date_shutdown ? parseISO(item.date_shutdown?.substring(0, 10)) : '',
          date_shutdown_formatted: item?.date_shutdown ? format(parseISO(item.date_shutdown?.substring(0, 10)), 'dd/MM/yyyy', { locale: ptBr }) : '',
          work_activities: item.work_activities,
          educational_institution: {
            ...item.educational_institution
          },
          duration: item?.duration,
          reason_shutdown: item?.reason_shutdown,
          insurance_number: item.insurance_number,
          start_validity: parseISO(item.start_validity.substring(0, 10)),
          start_validity_formatted: format(parseISO(item.start_validity?.substring(0, 10)), 'dd/MM/yyyy', { locale: ptBr }),
          end_validity: format(parseISO(item.end_validity?.substring(0, 10)), 'dd/MM/yyyy', { locale: ptBr }),
          end_validity_date: parseISO(item.end_validity?.substring(0, 10)),
          internship_scholarship_value: new Intl.NumberFormat('pt', {
            style: 'currency',
            currency: 'BRL'
          }).format(item.internship_scholarship_value),
          transportation_assistance_value: new Intl.NumberFormat('pt', {
            style: 'currency',
            currency: 'BRL'
          }).format(item.transportation_assistance_value),
          total_value: new Intl.NumberFormat('pt', {
            style: 'currency',
            currency: 'BRL'
          }).format(item.transportation_assistance_value + item.internship_scholarship_value),

          total_value_desligamento: parseFloat(item.transportation_assistance_value + item.internship_scholarship_value),

          company: {
            company_name: item?.company?.company_name?.toUpperCase(),
            cnpj: item?.company?.cnpj,
            address: item?.company?.address?.toUpperCase(),
            cep: item?.company?.cep,
            contact: item?.company?.contact,
            rh_analyst: item?.company.rh_analyst,
            supervisor: item?.company.supervisor,
            city: item?.company?.city?.toUpperCase(),
            state: item?.company?.state?.toUpperCase(),
            number: item?.company?.number?.toUpperCase(),
            neighborhood: item?.company?.neighborhood?.toUpperCase(),
            institution_value: item?.company?.institution_value,


          },

          supervisor_company: {
            name: item?.supervisor_company?.name || '',
            position: item?.supervisor_company?.position || ''
          },

          supervisor_education: {
            name: item?.supervisor_educational_institution?.name || '',
            position: item?.supervisor_educational_institution?.position || ''
          },

          trainee: {
            name: item.trainee?.name?.toUpperCase(),
            cpf: item.trainee?.cpf,
            cep: item.trainee?.cep,
            address: item.trainee?.address?.toUpperCase(),
            primary_phone_contact: item.trainee.primary_phone_contact,
            date_birth: item.trainee.date_birth ? format(parseISO(item.trainee.date_birth?.substring(0, 10)), 'dd/MM/yyyy') : '',
            number: item.trainee?.number,
            neighborhood: item.trainee?.neighborhood?.toUpperCase(),
            city: item?.trainee?.city?.toUpperCase(),
            state: item?.trainee?.state?.toUpperCase(),
            age: item?.trainee?.age,
            rg: item?.trainee?.rg,
            mother: item.trainee?.mother_name,
            father: item.trainee?.father_name,
            document_responsible: item.trainee?.document_responsible
          },
        }
      }));
    })
      .finally(() => setLoading(false))
  }, [getValues]);


  const handleReactiveContract = useCallback((row) => {
    Swal.fire({
      title: `Deseja realmente resetar o contrato do estagiário ${row.trainee_name} ? `,
      text: 'Os campos de data de desligamento, motivo do desligamento e status serão resetados ao prosseguir com essa ação.',
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        api.put(`/contracts/${row.id}/reactive`)
          .then(() => {
            Swal.fire('Resetado!', '', 'success');
            handleGetContract();
          }).catch(() => {
            Swal.fire('Houve um erro ao tentar executador essa ação', '', 'error');
          })
      }
    })
  }, [handleGetContract]);


  useEffect(() => {
    api.get('companies').then((response) => {
      setCompaniesOptions([...companiesOptions, ...response.data.map(item => {
        return {
          value: item.id,
          label: item.company_name,
        }
      })]);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    api.get('trainees').then((response) => {
      setTraineeOptions(response.data.map(item => {
        return {
          value: item.id,
          label: item.name
        }
      }));
    })
  }, []);

  return (
    <Container fluid>
      <Card>
        <Card.Header as="h5" className='d-flex justify-content-between align-items-center color-secondary'>
          <div>
            Contratos
          </div>
          <div>
            <Button
              onClick={() => history.push('/contratos/novo')}
              className="button-primary mr-4"
            >
              <i className="bi bi-plus-lg mr-2"></i>
              Novo Contrato
            </Button>

            <Button
            variant='secondary'
              onClick={handleExportClick}
              className="button-secondary"
            >
              <i className="bi bi-upload mr-2"></i>
              Exportar
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Form.Row>
            <Controller
              as={Select}
              control={control}
              name="trainee"
              label="Estagiário?"
              md="12"
              options={traineeOptions}
              required
            />

            <Controller
              as={Select}
              control={control}
              name="company_id"
              label="Qual a empresa ?"
              md="6"
              options={companiesOptions}
            />
            <Controller
              as={Select}
              control={control}
              name="status"
              label="Status dos contratos"
              md="6"
              options={[
                {
                  value: '',
                  label: 'Todos status'
                },
                {
                  value: true,
                  label: 'Contrato Ativos'
                },
                {
                  value: false,
                  label: 'Contrato Inativos'
                }
              ]}
            />
          </Form.Row>
          <div className='d-flex'>
            <Button
              onClick={handleGetContract}
              className="float-left button-primary mb-4"
              type="submit"
            >
              <FaFilter className='mr-2' />
              {loading ? 'Buscando...' : 'Filtrar'}
            </Button>
          </div>
          {loading ? (
            <LoadingIndicator /> // Mostra o LoadingIndicator durante o carregamento
          ) : (
          <ComplexTable
            data={contracts}
            columns={[
              {
                dataField: 'age',
                text: 'Idade',
                sort: true,
                filter: false,
              },
              {
                dataField: 'trainee_name',
                text: 'Estagiário',
                sort: true,
                filter: false,
              },


              {
                dataField: 'company_name',
                text: 'Empresa',
                sort: true,
                filter: false,
              },
              {
                dataField: 'status',
                text: 'Status',
                formatter: (_, row) => (
                  <>
                    {
                      row.status ? (
                        <Badge pill variant="success">
                          ATIVO
                        </Badge>) : (
                        <Badge pill variant="danger">
                          INATIVO
                        </Badge>
                      )
                    }
                  </>
                )
              },
              {
                dataField: 'id',
                text: 'Ações',
                sort: false,
                headerStyle: {
                  width: '35%',
                },
                formatter: (_, row) => (
                  <ActionFormatter
                  row={row}
                  handleGenerateContrato={handleGenerateContrato}
                  handleGenerateContratoTrainee={handleGenerateContratoTrainee}
                  handleGenerateTraineeRenovacao={handleGenerateTraineeRenovacao}
                  handleGenerateTerm={handleGenerateTerm}
                  handleReactiveContract={handleReactiveContract}
                />
                )
              },
            ]}
          />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ContractsList;
