/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import InputDate from '../../components/InputDate';


import { toast } from 'react-toastify';
import api from 'src/services/axios';

interface ContractData {
    start_validity: Date | undefined;
    end_validity: Date | undefined;
}

const RenovacaoForm: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const params = useParams();

    const { handleSubmit, control, errors } = useForm<ContractData>({
        reValidateMode: 'onSubmit',
        defaultValues: {
            start_validity: undefined,
            end_validity: undefined,
        }
    });

    const onSubmit = data => {

        if(!data.start_validity || !data.end_validity) {
            toast.error("Selecione a data inicial e final")
            return
        }
        

        setLoading(true);

        const dataContract = {
            start_validity: data.start_validity,
            end_validity: data.end_validity,
        };


        api.put(`/contracts/renewals/${params.id}`, dataContract)
            .then(() => {
                history.goBack();
                toast.success("Contrato renovado com sucesso!");
            })
            .catch(() => {
                toast.error("Houve um erro ao atualizar o contrato!");
            });

    };

    return (
        <Container>
            <Card>
                <Card.Header as='h5' style={{ background: 'none', border: 'none' }}>
                    Renovação de contrato
                </Card.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Card.Body style={{ margin: '0.5rem 1rem', background: 'white', borderRadius: '10px' }}>
                        <Form.Row>
                            <Controller
                                as={InputDate}
                                control={control}
                                name="start_validity"
                                label="Período vigência - Início"
                                md="6"
                                required
                                errorMessage={errors.start_validity?.message}
                            />
                            <Controller
                                as={InputDate}
                                control={control}
                                name="end_validity"
                                label="Período vigência - Fim"
                                md="6"
                                errorMessage={errors.end_validity?.message}
                                required
                            />
                        </Form.Row>
                    </Card.Body>
                    <Card.Footer>
                        <Form.Row>
                            <Button className="mr-3 button-primary" type="submit">
                                {loading ? 'Carregando...' : 'Salvar'}
                            </Button>
                            <Button onClick={() => history.goBack()} className="button-secondary-outline">
                                Cancelar
                            </Button>
                        </Form.Row>
                    </Card.Footer>
                </Form>
            </Card>
        </Container>
    );
}

export default RenovacaoForm;
