import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

// Array com as atualizações
const updates = [
  {
    "id": "update-1",
    "title": "🚀 Bem-vindo ao novo Estagius!",
    "message": "Descubra todas as melhorias e mudanças no Estagius, agora com um visual renovado, otimizado e pronto para você aproveitar ao máximo! Confira nas notas de atualizações no Rodapé"
  },
];


export const UpdateModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);

  // Verifica se há atualizações não vistas
  useEffect(() => {
    const seenUpdates = JSON.parse(localStorage.getItem("seenUpdates") || "[]");
    const newUpdates = updates.filter(
      (update) => !seenUpdates.includes(update.id)
    );

    if (newUpdates.length === 0) {
      setIsModalVisible(false);
    }
  }, []);

  // Fecha o modal e salva as atualizações vistas
  const closeModal = () => {
    const seenUpdates = updates.map((update) => update.id);
    localStorage.setItem("seenUpdates", JSON.stringify(seenUpdates));
    setIsModalVisible(false);
  };

  if (!isModalVisible) return null;

  return (
    <Modal centered size="lg" show={isModalVisible} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Novidades Recentes - Atualização 1.0</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {updates.map((update) => (
          <div key={update.id} className="mb-3">
            <h4>{update.title}</h4>
            <p>{update.message}</p>
            <hr />
          </div>
        ))}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={closeModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
