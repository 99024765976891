import React from "react";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { ThemeProvider } from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

import theme from "./styles/theme";

import { store, persistor } from "./store/index";
import history from "./services/history";
import "./global/global.css";
import "./themes/darkmode.css";
import "./themes/lightmode.css";
import "./themes/sidebar.css"
import "./themes/high-contrast.css";
import "./themes/variables.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Routes />
            <ToastContainer autoClose={3000} />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
