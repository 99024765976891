import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const MobileWarningModal: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Detecta se é um dispositivo móvel com base na largura da tela
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
        setShowModal(true); // Exibe o modal se for mobile
      } else {
        setIsMobile(false);
        setShowModal(false); // Esconde o modal se for desktop
      }
    };

    // Verificação inicial
    handleResize();

    // Verifica novamente quando a janela é redimensionada
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => {
    setShowModal(false); // Fecha o modal quando o usuário clica no botão
  };

  return (
    <>
      {/* Renderiza o modal apenas se for detectado como mobile */}
      {isMobile && (
        <Modal show={showModal} onHide={handleClose} backdrop="static" centered>
          <Modal.Header className="bg-secondary-c" closeButton>
            <Modal.Title>Aviso</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-default">
            Este sistema não foi totalmente adaptado para dispositivos móveis.
            Alguns recursos podem não estar disponíveis ou funcionar
            corretamente em celulares. Melhorias serão feitas em breve!
          </Modal.Body>
          <Modal.Footer className="bg-secondary-c">
            <Button variant="primary" onClick={handleClose}>
              Entendido
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default MobileWarningModal;
