import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Card, Form } from 'react-bootstrap';
import Input from '../../components/Input';
import { toast } from 'react-toastify';

interface SupervisorFormProps {
  onAddSupervisor: (data: { name: string; position: string }) => Promise<void>;
}

const SupervisorForm: React.FC<SupervisorFormProps> = ({ onAddSupervisor }) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      position: '',
    },
  });

  const onSubmit = async (data) => {
    try {
      await onAddSupervisor(data);
      reset(); // Limpa o formulário após adição
    } catch (error) {
      toast.error('Erro ao adicionar supervisor!');
    }
  };

  return (
    <Card>
      <Card.Header as='h5'>Adicionar Supervisor</Card.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <Form.Row>
            <Controller
              as={Input}
              control={control}
              name="name"
              label="Nome do Supervisor"
              md="6"
              required
            />
            <Controller
              as={Input}
              control={control}
              name="position"
              label="Cargo do Supervisor"
              md="6"
              required
            />
          </Form.Row>
        </Card.Body>
        <Card.Footer>
          <Button type="submit" variant="success">  <i className="bi bi-plus-lg me-2"></i> Adicionar</Button>
        </Card.Footer>
      </Form>
    </Card>
  );
};

export default SupervisorForm;
