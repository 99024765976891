import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// Importações do bootstrap
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
// Meus componentes
import ComplexTable from "../../../components/ComplexTable";

//services
import api from "../../../services/axios";
import Icon from "../../../components/icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  handleGenerateContractCompanyTwo,
  handleGenerateTermoAnexo,
  handleGenerateTermoParceriaEscolaEmpresa,
} from "src/screens/Relatorios/templates/report";
import { toast } from "react-toastify";
import { formatCNPJ, ReplaceOnlyDigits } from "src/helpers";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

const EmpresasList: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setLoading(true); // Ativa o estado de carregamento
    api
      .get("companies")
      .then((response) => {
        setCompanies(
          response.data.map((item) => ({
            ...item,
            cnpj: ReplaceOnlyDigits(item.cnpj),
          }))
        );
      })
      .catch((error) => {
        console.error("Erro ao buscar empresas:", error);
      })
      .finally(() => {
        setLoading(false); // Garante que o loading será desativado, independentemente do sucesso ou erro
      });
  }, []);

  const handleAccessCredentials = (id: number) => {
    api.post("/companies/access", { company_id: id }).then((response) => {
      toast.warning(response.data.message);
    });
  };

  const handleExportClick = () => {
    api
      .get("companies/export", { responseType: "blob" })
      .then((response) => {
        // Cria um URL temporário para o arquivo CSV
        const url = URL.createObjectURL(response.data);

        // Cria um link de download para o arquivo CSV
        const link = document.createElement("a");
        link.href = url;
        link.download = "empresas.csv";
        link.click();

        // Libera o URL temporário
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Erro ao exportar CSV:", error);
      });
  };

  return (
    <Container fluid>
      <Card style={{}}>
        <Card.Header
          className="d-flex justify-content-between align-items-center color-secondary"
          as="h5"
        >
          <div>Empresas</div>
          <div>
            <Button
              variant="secondary"
              onClick={() => history.push("/empresas/novo")}
              className="mr-4"
            >
              <i className="bi bi-plus-lg mr-2"></i>
              Nova empresa
            </Button>
            <Button variant="secondary" onClick={handleExportClick}>
              <i className="bi bi-upload mr-2"></i>
              Exportar
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <LoadingIndicator /> // Mostra o LoadingIndicator durante o carregamento
          ) : (
            <ComplexTable
              data={companies}
              columns={[
                {
                  dataField: "__meta__.qtd_contracts_actives",
                  text: "Ordem",
                  sort: true,
                  filter: false,
                  headerStyle: {
                    width: "2%",
                  },
                  formatter: (_, row) => (
                    <OverlayTrigger
                      key="edit"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          {Number(row.__meta__.qtd_contracts_actives) > 0
                            ? "Estagiários ativos"
                            : "Sem estagiários"}
                        </Tooltip>
                      }
                    >
                      <div
                        style={{
                          height: "30px",
                          color: "#FFF",
                          fontWeight: "bold",
                          borderRadius: "50px",
                          width: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background:
                            Number(row.__meta__.qtd_contracts_actives) > 0
                              ? "var(--green-regular)"
                              : "var(--red-regular)",
                        }}
                      >
                        {row.__meta__.qtd_contracts_actives}
                      </div>
                    </OverlayTrigger>
                  ),
                },
                {
                  dataField: "amount_clt",
                  text: "Clt",
                  sort: false,
                  filter: true,
                  headerStyle: {
                    width: "2%",
                  },
                  formatter: (_, row) => (
                    <span>
                      {" "}
                      {row.amount_clt
                        ? `${row.amount_clt}/${(row.amount_clt / 100) * 20}`
                        : "N/D"}
                    </span>
                  ),
                },
                {
                  dataField: "city",
                  text: "Cid.",
                  sort: false,
                  filter: true,
                  headerStyle: {
                    width: "2%",
                  },
                },
                {
                  dataField: "cnpj",
                  text: "CNPJ",
                  sort: true,
                  filter: true,
                  formatter: (_, row) => <span>{formatCNPJ(row.cnpj)}</span>,
                },
                {
                  dataField: "company_name",
                  text: " Razão social",
                  sort: true,
                  filter: true,
                },
                {
                  dataField: "fantasy_name",
                  text: "Nome Fantasia",
                  sort: true,
                  filter: true,
                },
                {
                  dataField: "rh_analyst",
                  text: "E-mail RH",
                  sort: true,
                  filter: true,
                },
                {
                  dataField: "supervisor",
                  text: "E-mail Financeiro",
                  sort: true,
                  filter: true,
                },

                {
                  dataField: "id",
                  text: "Ações",
                  sort: false,
                  headerStyle: {
                    width: "15%",
                  },
                  formatter: (_, row) => (
                    <div className="centered">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          id={`dropdown-${row.id}`}
                        >
                          <Icon icon="FaEllipsisV" size={20} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              history.push(`/empresas/supervisores/${row.id}`)
                            }
                          >
                            <Icon icon="FaUserPlus" className="mr-2" />
                            Adicionar Supervisor
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleGenerateTermoParceriaEscolaEmpresa(row)
                            }
                          >
                            <Icon icon="FaHandshake" className="mr-2" />
                            Termo de Parceria Escola-Empresa
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleGenerateTermoAnexo(row)}
                          >
                            <Icon icon="FaFileSignature" className="mr-2" />
                            Anexo - Termo de Convênio
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleGenerateContractCompanyTwo(row)
                            }
                          >
                            <Icon icon="FaFileContract" className="mr-2" />
                            Termo de Estágio
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              history.push(`empresas/${row.id}/pastas`, {
                                name: row.company_name,
                              })
                            }
                          >
                            <Icon icon="FaFolderOpen" className="mr-2" />
                            Documentos da Empresa
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleAccessCredentials(row.id)}
                          >
                            <Icon icon="FaKey" className="mr-2" />
                            Disponibilizar Credenciais
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Icon
                        icon="FaEdit"
                        className="mr-2 interaction"
                        onClick={() => history.push(`empresas/${row.id}`)}
                      />
                    </div>
                  ),
                },
              ]}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EmpresasList;
