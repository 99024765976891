import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { handleGenerateReportAttendanceCount } from '../../templates/report'

import ExportJsonExcel from 'js-export-excel'

// Componentes do react-bootrap
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form';

// Componentes da aplicação
import Select from '../../../../components/Select';
import InputDate from '../../../../components/InputDate'
import ComplexTable from '../../../../components/ComplexTable';

import api from 'src/services/axios';
import { toast } from 'react-toastify';
import { FaFilePdf, FaFilter } from 'react-icons/fa';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';

const AttendanceReportCount: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  const [companiesOptions, setCompaniesOptions] = useState([{
    value: '',
    label: 'Todas'
  }]);

  const { handleSubmit, control } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      company_id: [
        {
          value: '',
          label: 'TODAS'
        }
      ],
      date_start: undefined,
      date_end: undefined,
      typeOptions: {
        value: '',
        label: 'Tudo'
      }
    }
  });

  const OnSubmit = useCallback((data) => {
    setLoading(true)
    let queryParams = new URLSearchParams();


    if (!data.date_start && !data.date_end) {
      toast.error('Selecione uma faixa de datas')
      return
    }

    queryParams.set('date_start', format(data.date_start, 'yyyy-MM-dd'));
    queryParams.set('date_end', format(data.date_end, 'yyyy-MM-dd'));
    queryParams.set('type', data.typeOptions.value);


    if (data.company_id) {
      queryParams.set('company_id', data.company_id.reduce((acc, current) => {
        return [...acc, current.value]
      }, []));
    }


    api.get(`/reports/attendances/count?${queryParams}`).then(response => {
      setData(response.data.map(item => {
        return {
          id: uuidv4(),
          cpf: item.cpf,
          type: item.type,
          trainee_name: item.name,
          company_name: item.company_name,
          primary_phone_contact: item.primary_phone_contact,
          count: item.count,
          date: format(parseISO(item.start_validity.substring(0, 10)), 'dd/MM/yyyy')
        }
      }));
    })
      .finally(() => setLoading(false))
  }, []);


  const handleGenerateExcelFile = () => {

    const parseData = data.map(item => {
      return {
        count: item.count,
        company_name: item.company_name,
        name: item.trainee_name,
        cpf: item.cpf,
        primary_phone_contact: item.primary_phone_contact,
        type: item.type
      }
    })

    let option = {
      fileName: `${Date.now()}-presencas`,
      datas: [
        {
          sheetData: parseData,
          sheetName: 'sheet',
          sheetFilter: ['count', 'company_name', 'name', 'cpf', 'primary_phone_contact', 'type'],
          sheetHeader: ['Qtd', 'Empresa', 'Nome', 'Cpf', 'Contato', 'Tipo'],
        }
      ]
    }

    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }


  useEffect(() => {
    api.get('companies').then((response) => {
      setCompaniesOptions([...companiesOptions, ...response.data.map(item => {
        return {
          value: item.id,
          label: item.company_name,
        }
      })]);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Card style={{  }}>
        <Card.Header as="h5" className='color-secondary d-flex  align-items-center'>
          <div>
            Relatório de presenças com contagem
          </div>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(OnSubmit)}>
            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name="date_start"
                label="Data inicial"
                md="6"
              />
              <Controller
                as={InputDate}
                control={control}
                name="date_end"
                label="Data final"
                md="6"
              />
            </Form.Row>

            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="company_id"
                label="Qual a empresa ?"
                md="12"
                options={companiesOptions}
                isMulti
              />

              <Controller
                as={Select}
                control={control}
                name="typeOptions"
                label="Presença/Faltas"
                md="12"
                options={[
                  {
                    value: '',
                    label: 'Tudo'
                  },
                  {
                    value: 'PRESENÇA',
                    label: 'Apenas presenças'
                  },
                  {
                    value: 'FALTA',
                    label: 'Apenas faltas'
                  }
                ]}
              />

            </Form.Row>
            <div className='d-flex justify-content-between align-items-center mb-4'>
              <Button
                variant="primary"
                onClick={() => { }}
                type="submit"
              >
                <FaFilter className='mr-2' />
                {loading ? 'Buscando...' : 'Filtrar'}
              </Button>
              <div className='d-flex align-items-center'>
                <Button
                  type="button"
                  variant='secondary'
                  onClick={() => handleGenerateReportAttendanceCount(data, '')}
                  className="button-secondary-outline mr-3"
                >
                  <FaFilePdf className='mr-2' size={20} />
                  Gerar PDF
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => handleGenerateExcelFile()}
                  className="secondary"
                >
                  <RiFileExcel2Fill className='mr-2' />
                  Gerar Excel
                </Button>
              </div>
            </div>
          </Form>
          {loading ? (
            <LoadingIndicator /> // Mostra o LoadingIndicator durante o carregamento
          ) : (
            <ComplexTable
              keyField='id'
              data={data}
              columns={[

                {
                  dataField: 'company_name',
                  text: 'Empresa',
                  sort: false,
                  filter: false,
                },
                {
                  dataField: 'date',
                  text: 'Data do contrato',
                  sort: true,
                  filter: false,
                },
                {
                  dataField: 'trainee_name',
                  text: 'Estagiário',
                  sort: true,
                  filter: false,
                },
                {
                  dataField: 'cpf',
                  text: 'CPF',
                  sort: true,
                  filter: false,
                },
                {
                  dataField: 'primary_phone_contact',
                  text: 'Telefone',
                  sort: true,
                  filter: false,
                },
                {
                  dataField: 'type',
                  text: 'Presença/Falta',
                  sort: false,
                  filter: false,
                },
                {
                  dataField: 'count',
                  text: 'Quantidade',
                  sort: true,
                  filter: false,
                },
              ]}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AttendanceReportCount;
