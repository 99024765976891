import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Input from '../../../../components/Input';
import InputMoney from '../../../../components/InputMoney';
import Select from '../../../../components/Select';
import { ReplaceOnlyDigits } from '../../../../helpers/index'
import api from 'src/services/axios';
import Swal from 'sweetalert2'
interface FormValues {
  amount: 0,
  description: string;
  company: null | {
    value: string;
    label: string;
  };
  billingName: string;
  billingDocument: string;
  billingNumber: string;
  billingNeighborhood: string;
  billingState: string;
  billingCity: string;
  billingCep: string;
}


const ChargesForm: React.FC = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();


  const [companiesOptions, setCompaniesOptions] = useState([]);

  const {
    control,
    watch,
    setValue,
    handleSubmit
  } = useForm<FormValues>({
    defaultValues: {
      amount: 0,
      description: '',
      company: null,
      billingName: '',
      billingDocument: '',
      billingNumber: '',
      billingNeighborhood: '',
      billingState: '',
      billingCity: '',
      billingCep: '',
    }
  })

  useEffect(() => {
    api.get('companies').then((response) => {
      setCompaniesOptions(response.data.map(item => {
        return {
          value: item.id,
          label: item.company_name,
        }
      }));
    })
  }, []);


  useEffect(() => {
    if (params?.id) {
      api.get(`companies/${params.id}`)
        .then(response => {
          setValue('amount', location.state.amount)
          setValue('billingName', response.data.company_name)
          setValue('billingDocument', response.data.cnpj)
          setValue('billingAddrress', response.data.address)
          setValue('billingNeighborhood', response.data.neighborhood)
          setValue('billingState', response.data.state)
          setValue('billingCity', response.data.city)
          setValue('billingCep', response.data.cep)
          setValue('billingNumber', response.data.number)
          setValue('company', { value: params.id, label: response.data.company_name })

        })
    }
  }, [location, params, setValue])


  const company = watch('company')

  useEffect(() => {
    if (company) {
      api.get(`companies/${company?.value}`)
        .then(response => {
         
          setValue('billingName', response.data.company_name)
          setValue('billingDocument', response.data.cnpj)
          setValue('billingAddrress', response.data.address)
          setValue('billingNeighborhood', response.data.neighborhood)
          setValue('billingState', response.data.state)
          setValue('billingCity', response.data.city)
          setValue('billingCep', response.data.cep)
          setValue('billingNumber', response.data.number)
        })
    }
  }, [company, setValue])

  const handleSubmitForm = (data) => {

    const body = {
      "charge": {
        "description": data.description,
        "amount": data.amount
      },
      "billing": {
        "name": data.billingName,
        "document": ReplaceOnlyDigits(data.billingDocument),
        "address": {
          "street": data.billingAddrress,
          "number": data.billingNumber,
          "neighborhood": data.billingNeighborhood,
          "city": data.billingCity,
          "state": data.billingState,
          "postCode": ReplaceOnlyDigits(data.billingCep),
        }
      }
    }


    api.post('/juno/charges', body)
      .then(response => {
        history.goBack()
        Swal.fire(
          'Sucesso',
          response.data.data._embedded?.charges[0]?.link,
          'success'
        )
      })
      .catch(error => {
     
      })

  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Card >
          <Card.Header as="h5" className='color-secondary d-flex justify-content-between align-items-center'>
            Cobranças - Boleto
            <Button
              type="submit"
              className="float-right button-primary"
            >
               <i className="bi bi-plus-lg mr-2"></i>
              Gerar boleto
            </Button>
          </Card.Header>
          <Card.Body>
            <p style={{ color: '#999', fontSize: '16px' }}>
              Dados do pagador
            </p>


            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="company"
                label="Empresa"
                md="12"
                options={companiesOptions}
                required
              />

              <Controller
                as={Input}
                control={control}
                label="Documento"
                name="billingDocument"
                disabled
                md="12"
              />

            </Form.Row>

            <hr />

            <Form.Row>
              <Controller
                as={Input}
                control={control}
                label="Nome"
                name="billingName"
                disabled
                md="12"
              />
              <Controller
                as={Input}
                control={control}
                label="Rua"
                name="billingAddrress"
                disabled
                md="12"

              />

              <Controller
                as={Input}
                control={control}
                label="Bairro"
                name="billingNeighborhood"
                disabled
              />

              <Controller
                as={Input}
                control={control}
                label="Número"
                name="billingNumber"
                disabled
              />

              <Controller
                as={Input}
                control={control}
                label="Estado"
                name="billingState"
                disabled
              />
              <Controller
                as={Input}
                control={control}
                label="Cidade"
                name="billingCity"
                disabled
              />
              <Controller
                as={Input}
                control={control}
                label="Cep"
                name="billingCep"
                disabled
              />
            </Form.Row>

            <p style={{ color: '#999', fontSize: '16px' }}>
              Dados do boleto
            </p>

            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name="description"
                label="Descrição:"
                md="12"
              />

            </Form.Row>
            <Form.Row>
              <Controller
                as={InputMoney}
                control={control}
                name="amount"
                label="Valor do boleto"
                md="6"
                required
              />
            </Form.Row>

          </Card.Body>
        </Card>
      </Form>
    </Container>

  )
}

export default ChargesForm;