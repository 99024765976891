import React from "react";
import './style.css'

export const Footer = () => {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  
  return (
      <footer>
        <p>
         Estagius todos os direitos reservados 2019 - {getCurrentYear()} | <b className="text-primary">v1.4</b>
        </p>
      </footer>
  );
};


