import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
// Importações do bootstrap
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

// Meus componentes
import ComplexTable from '../../../../components/ComplexTable';

//services
import api from '../../../../services/axios';
import { toast } from 'react-toastify';

const ChargesList: React.FC = () => {
  const history = useHistory();

  const [charges, setCharges] = useState([]);

  useEffect(() => {
    api.get('/juno/charges')
      .then((response) => {
      
        setCharges(response.data.data._embedded?.charges.map(item => {
          return {
            id: item.id,
            status: item.status + ' - ' + item.payNumber,
            dueDate: item.dueDate,
            amount: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount),
            link: item.link,
            checkoutUrl: item.checkoutUrl,
            barcodeNumber: item.billetDetails.barcodeNumber
          }
        }));
      }).catch((error) => {
        if (error?.response?.data?.error) {
          toast.error(error.response.data.error)
          return
        }
        toast.error('Houve um erro ao tentar completar essa operação')
      })
  }, []);

  const expandRow = {
    renderer: row => (
      <>
        <p>  Código de barras: {row.barcodeNumber}</p>
      </>
    )
  };




  return (

    <Container fluid>
      <Card >
        <Card.Header className='d-flex align-items-center justify-content-between color-secondary'  as="h5">
          Cobranças - Boleto
          <Button
            onClick={() => history.push('/financeiro/boletos/novo')}
            className="button-primary"
          >
             <i className="bi bi-plus-lg mr-2"></i>
            Novo boleto
          </Button>
        </Card.Header>
        <Card.Body>
          <ComplexTable
            key="id"
            data={charges}
            columns={[

              {
                dataField: 'status',
                text: 'Status',
                sort: false,
                filter: false,
              },
              {
                dataField: 'amount',
                text: 'Valor',
                sort: false,
                filter: false,
              },
              {
                dataField: 'dueDate',
                text: 'Vencimento',
                sort: false,
                filter: false,
              },
              {
                dataField: 'link',
                text: 'Link',
                sort: false,
                filter: false,
                formatter: (_, row) => (

                  <>
                    <a href={row.link} rel="noopener noreferrer" target="_blank">Ver PDF da cobrança</a>
                  </>
                )
              },

              {
                dataField: 'checkoutUrl',
                text: 'Mais detalhes',
                sort: false,
                filter: false,
                formatter: (_, row) => (

                  <>
                    <a href={row.checkoutUrl} rel="noopener noreferrer" target="_blank">Ver detalhes</a>
                  </>
                )
              },

            ]}
            expandRow={expandRow}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ChargesList;
