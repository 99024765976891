
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import pdfMake from 'pdfmake/build/pdfmake';
// eslint-disable-next-line import/no-extraneous-dependencies
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { compareDate, compareTraineeName } from 'src/helpers';
import {LOGO_DR_ESTAGIO } from '../../Contratos/List/assinatura';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const handleGenerateTermoParceriaEscolaEmpresa = (data) => {

  const dataDoConvenio = data.agreement_start_date ? format(parseISO(data.agreement_start_date.substring(0,10)), "dd 'de' MMMM 'de' yyyy", { locale: ptBR }) : '';

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 60, 40, 60],
    /*
    header: {
      image: IGT_LOGO,
      width: 120,
      height: 43,
      alignment: 'center',
      margin: 10
    },*/
    content: [
      { text: `TERMO DE CONVÊNIO\n INSTITUIÇÃO DE ENSINO E EMPRESA\n\n`, alignment: 'center', fontSize: 12, bold: true },

      // {
      //   text: [
      //     `CONVÊNIO QUE ENTRE SI CELEBRAM MARIA GORETH TAVARES PEREIRA E A EMPRESA ${data.company_name}, NO INTUITO DE POSSIBILITAR AOS ESTAGIÁRIOS CAPACITAÇÃO PROFISSIONAL.\n\n`
      //   ],
      //   alignment: 'justify',
      //   fontSize: 12,
      //   bold: true
      // },

      {
        text: [
          `Celebra o presente TERMO DE CONVÊNIO A instituição de ensino Maria Goreth Tavares Pereira  (IGT) localizado na Rua Tabelião José Gama Filho, N° 540, sala 03 – Centro - Pacajus – CE, inscrita no CNPJ sob o nº 11.807.608/0001-49, doravante denominado 1° CONVENENTE, neste ato representado por seu Diretor(a): Maria Goreth Tavares Pereira e ${data.company_name} estabelecido na Rua ${data.address}, número ${data.number} – Bairro - ${data.neighborhood}, Munícipio ${data.city}, CEP ${data.cep}, inscrito no CNPJ sob o nº: ${data.cnpj}, doravante denominado 2° CONVENENTE, as partes acima qualificadas resolvem nos termos da Lei n. 11.788 de 25 de setembro de 2008, mediante as cláusulas e condições a seguir expressas: \n\n`
        ],
        alignment: 'justify',
        fontSize: 12,
        //bold: true
      },


      // { text: 'PRIMEIRA CONVENENTE - ESCOLA:\n', alignment: 'justify', fontSize: 12, bold: true },

      {
        text: [
          { text: 'Cláusula Primeira – Do Objetivo\n', bold: true },
          ` O presente Convênio objetiva estabelecer as bases gerais para a realização de um Programa de Estágio para os alunos do Instituto Goreth Tavares (IGT), a realizar-se nas unidades que estejam disponíveis na(o) ${data.company_name} `,
        ]
        , alignment: 'justify', fontSize: 11
      },

      {
        text: [
          { text: 'Parágrafo Único:', bold: true },
          ` O Programa de Estágio a que se refere o caput da cláusula primeira compreende o estágio extracurricular remunerado, componente prático que contribui para a carreira do estudante do Instituto Goreth Tavares.\n `,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          { text: 'Cláusula Segunda – Da Natureza do Programa de Estágio\n', bold: true },
          ` Considera-se estágio extracurricular, as atividades opcionais, acrescida à carga horária regular e parte do projeto pedagógico do curso. O programa de estágio visa a aprendizagem social, profissional e cultural, proporcionadas ao estudante pela participação em situações reais da vida e trabalho no seu meio, de acordo com o § 2o do Art. 2°, da Lei n° 11.788 de 25 de setembro de 2008.\n `,
        ]
        , alignment: 'justify', fontSize: 11
      },

      {
        text: [
          { text: 'Cláusula Terceira – Da finalidade do Programa de Estágio\n', bold: true },
          `O Programa de Estágio tem como finalidade dar ensejo a aplicação dos conhecimentos adquiridos no curso, permitindo o desenvolvimento das habilidades técnico-científicas, para melhor qualificação do futuro profissional, e oferecer subsídios à revisão curricular, à adequação de programas e metodologias.\n\n`,
        ]
        , alignment: 'justify', fontSize: 11
      },

      {
        text: [
          { text: 'Cláusula Quarta – Da Inexistência do Vínculo Empregatício\n', bold: true },
          `O estagiário não terá vínculo empregatício com a 2° convenente, conforme disposição do art. 3°, da Lei nº 11.788 de 25 de setembro de 2008. \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },

      {
        text: [
          { text: 'Cláusula Quinta – Obrigações da 1° Convenente\n', bold: true },
          `1.	A Instituição de Ensino fica obrigada a manter e fiscalizar a Matrícula e frequência regular do educando em curso de educação profissional, atestados pela instituição de ensino; \n\n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `2.	A Instituição de Ensino fica obrigada a averiguar a compatibilidade entre as atividades desenvolvidas no estágio e aquelas previstas no termo de compromisso. \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `3.	Indicar orientador, da área a ser desenvolvida no estágio, como responsável pelo acompanhamento e avaliação das atividades do estagiário; \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `4.	Controlar a frequência e avaliar o rendimento dos alunos estagiários; \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `5.	Celebrar termo de compromisso com o educando ou com seu representante ou assistente legal, quando ele for absoluto ou relativamente incapaz, e com a parte concedente, indicando as condições de adequação do estágio à proposta pedagógica do curso, à etapa e modalidade da formação escolar do estudante e ao horário e calendário escolar; \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `6.	Exigir a apresentação periódica, em prazo não superior a 6 (seis) meses, dos relatórios das atividades, com vista obrigatória ao estagiário; \n\n`,
        ]
        , alignment: 'justify', fontSize: 11
      },

      {
        text: [
          { text: 'Cláusula Sexta - Obrigações da 2° Convenente \n', bold: true },
          `1.	Receber no campo de estágio, os alunos dos cursos de educação profissional, do Instituto Goreth Tavares, que estiverem regularmente matriculados, obedecendo a quantidade máxima permitida no artigo art. 17, da Lei nº 11.788/ 2008; \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `2.	Indicar funcionário de seu quadro de pessoal, com formação ou experiência profissional na área de conhecimento desenvolvida no curso do estagiário, para orientar e supervisionar até 10 (dez) estagiários simultaneamente. \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `3.	Formalizar, a favor do estagiário, o seguro contra acidentes pessoais, em conformidade com o disposto no artigo 9º, Inciso IV da Lei nº 11.788 de 25 de setembro de 2008; \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `4.	Oferecer mensalmente ao estagiário, um auxilio-bolsa, cujo pagamento lhe será feito diretamente e com base no total de horas de estágio, concedendo ainda auxílio transporte; \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          { text: 'Parágrafo Único:', bold: true },
          ` A importância referente à bolsa, por não ter natureza salarial, uma vez que a realização de estágio não acarreta vínculo empregatício, desde que obedecidos os dispositivos da Lei nº 11.788/08, não estará sujeita a qualquer desconto trabalhista, previdenciário ou mesmo contribuição de FGTS.\n `,
        ]
        , alignment: 'justify', fontSize: 11
      },

      {
        text: [
          { text: 'Cláusula Sétima – Da Jornada de estágio\n', bold: true },
          ` A jornada de atividade em estágio será definida de comum acordo entre a instituição de ensino, a parte concedente e o aluno estagiário ou seu representante legal, devendo constar do termo de compromisso ser compatível com as atividades escolares e não ultrapassar:\n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `I – 04 (quatro) horas diárias e 20 (vinte) horas semanais, no caso de estudantes de educação especial e dos anos finais do ensino fundamental, na modalidade profissional de educação de jovens e adultos; \n`,
        ]
        , alignment: 'justify', fontSize: 11
      },
      {
        text: [
          `II – 06 (seis) horas diárias e 30 (trinta) horas semanais, no caso de estudantes do ensino superior, da educação profissional de nível médio e do ensino médio regular. \n\n`,
        ]
        , alignment: 'justify', fontSize: 11
      },

      {
        text: [
          { text: 'Cláusula Oitava - Da Vigência e da Rescisão\n', bold: true },
          ` O presente Convênio terá vigência por prazo indeterminado, a partir desta data, podendo ser rescindido a qualquer tempo, sem quaisquer ônus, promover a resilição do presente contrato, devendo a parte contrária ser comunicada com antecedência mínima de 30 (trinta) dias. Em caso de descumprimento contratual, poderão as partes, independentemente de aviso prévio, promover a imediata extinção do contrato, sem prejuízo da apuração de eventuais perdas e danos causados pela parte infratora.\n `,
        ]
        , alignment: 'justify', fontSize: 11
      },

      {
        text: [
          { text: 'Cláusula Nona - Do Foro\n', bold: true },
          ` Os casos omissos serão resolvidos com base nas disposições da Lei 11.788 de 25 de setembro de 2008, ficando eleito o Foro da Comarca da Capital do Estado de Ceará, renunciando desde logo, a qualquer outro por mais privilegiado que seja, para dirimir conflitos oriundos deste instrumento, ressalvada a composição amigável.\n `,
        ]
        , alignment: 'justify', fontSize: 11
      },

      {
        text: [
          `E, por estarem, assim, de acordo, assinam o presente convênio:\n\n`,
        ]
        , alignment: 'justify', fontSize: 11
      },




      // { text: 'SEGUNDA CONVENENTE - EMPRESA:\n', alignment: 'justify', fontSize: 12, bold: true },

      // {
      //   text: [
      //     { text: `${data.company_name}, `, bold: true },
      //     `pessoa jurídica de direito privado, estabelecida nesta cidade de ${data.city}/CE, sito a rua ${data.address}, número ${data.number} – Bairro - ${data.neighborhood}, Munícipio ${data.city}, CEP ${data.cep}, inscrita no CNPJ ${data.cnpj}, doravante designada`,
      //     { text: ' SEGUNDA CONVENENTE.\n', bold: true },
      //   ],
      //   alignment: 'justify',
      //   fontSize: 11,
      // },

      // { text: 'DO OBJETO\n', alignment: 'justify', fontSize: 12, bold: true },
      // { text: 'Constitui objeto da presente parceria a oferta de curso(s) de educação profissional.\n', alignment: 'justify', fontSize: 11, },
      // { text: 'DA METODOLOGIA\n', aalignment: 'justify', fontSize: 12, bold: true },
      // { text: 'O curso será ministrado na modalidade EAD com duração de 12 meses podendo ser prorrogado por mais 12 meses, com carga horária de 600h, distribuídas em 150 aulas e atividades. O aluno terá o limite de 21 faltas, ao exceder este limite será caracterizado abandono de curso e a matricula será encerrada, acarretando no desligamento do estagiário. As aulas são disponibilizadas por 03 dias, o aluno deverá entrar na plataforma, assistir as aulas e realizar a atividade do módulo da semana. Sendo estabelecidos de 06:00h de segunda-feira a 23:00h de Quarta-feira.\n', alignment: 'justify', fontSize: 11 },

      // { text: 'DAS OBRIGAÇÕES\n', alignment: 'justify', fontSize: 12, bold: true },

      // {
      //   text: [
      //     'Constituem obrigações da ',
      //     { text: 'PRIMEIRA CONVENENTE,', alignment: 'justify', fontSize: 11, bold: true },
      //     'desenvolver e ministrar o curso acima citado, assim como o acompanhamento de frequencia individual de cada aluno.\n'
      //   ], alignment: 'justify', fontSize: 11
      // },

      // {
      //   text: 'A escola se reserva no direito de fazer o encerramento da matricula do aluno, no caso do limite de faltas excedidas, no caso de faltas injustificadas do aluno em um período de 30 dias corridos caracterizará também o abandono de curso.\n',
      //   alignment: 'justify',
      //   fontSize: 11
      // },

      // {

      //   text: [
      //     'Constituem obrigações da ',
      //     { text: 'SEGUNDA CONVENENTE, ', alignment: 'justify', fontSize: 11, bold: true },
      //     'comunicar a escola via e-mail sempre que tiver algum desligamento de estagiário tanto por solicitação da empresa como por parte do aluno. Ressaltamos que o desligamento não gera multa contratual.\n'
      //   ]
      //   , alignment: 'justify'
      //   , fontSize: 11
      // },
      // {
      //   text: [
      //     'Acompanhar os relatórios de presenças e desempenho que serão enviados pela ',
      //     { text: 'PRIMEIRA CONVENENTE, ', alignment: 'justify', fontSize: 11, bold: true },
      //     'tomando as decisões necessárias para com aqueles que não estejam se adequando dentro do esperado.\n'
      //   ]
      //   ,
      //   alignment: 'justify',
      //   fontSize: 11
      // },
      // {
      //   text: [
      //     'Designar um orientador para acompanhamento periódico dos estagiários em suas atividades junto a unidade concedente;\n',
      //   ]
      //   ,
      //   alignment: 'justify',
      //   fontSize: 11
      // },

      // {
      //   text: [
      //     'Parágrafo Primeiro – A presente parceria não cria vínculo, ou qualquer obrigação de natureza trabalhista entre as partes.\n',
      //   ]
      //   ,
      //   alignment: 'justify',
      //   fontSize: 11
      // },

      // { text: 'DO PAGAMENTO', alignment: 'justify', fontSize: 12, bold: true },

      // { text: `Na condição de Unidade Concedente pagará mensalmente à instituição de ensino, o valor de ${valorDinheiro} por cada aluno que esteja realizando os cursos de qualificação profissional, em decorrência do presente Convênio.\n`, alignment: 'justify', fontSize: 11 },
      // { text: 'O valor da contribuição mencionado será reajustado a cada 12 (doze) meses, contado a partir da data de sua vigência, pelo INPC (Índice nacional de preço ao consumidor).\n', alignment: 'justify', fontSize: 11 },

      // { text: 'Parágrafo 1º - Na hipótese de atraso do pagamento da receita institucional poderá a instituição de ensino parceira a qualquer tempo e a seu exclusivo critério promover a ação judicial cabível em face da Unidade Concedente para o cumprimento da obrigação contraída, bem como promover o cancelamento do presente Convênio.\n', alignment: 'justify', fontSize: 11 },

      // { text: 'Parágrafo 2º - Esse valor deverá ser pago mensalmente a instituição de ensino, por meio de BOLETO, em relação a cada aluno, a Unidade Concedente efetuará até o dia 5 (Cinco) do mês subsequente, após a assinatura do mesmo, com envio eletrônico juntamente com a nota fiscal.\n', alignment: 'justify', fontSize: 11 },
      // { text: 'Parágrafo 3º - Os valores dessas contribuições destinam- se à consecução dos objetivos político-pedagógicos da unidade de ensino, à cobertura dos custos técnicos e administrativos;\n', alignment: 'justify', fontSize: 11 },


      // { text: 'DA RESCISÃO\n', alignment: 'justify', fontSize: 12, bold: true },
      // { text: 'A rescisão poderá ser de iniciativa unilateral, onde as partes deveram ser comunicadas com antecedência mínima de 30 (trinta) dias, ou sempre que verificado o descumprimento de qualquer uma de suas cláusulas não gerando custos para ambas as partes.\n', alignment: 'justify', fontSize: 11 },


      // { text: 'DA LEI GERAL DE PROTEÇÃO DE DADOS  \n', alignment: 'justify', fontSize: 12, bold: true },
      // { text: 'CLÁUSULA 9ª: A INSTITUIÇÃO DE ENSINO obriga-se ao dever de proteção, confidencialidade e sigilo de toda informação, dados pessoais e/ou base de dados a que tenha acesso por meios físicos ou eletrônicos em razão da operação, nos termos da Lei 13.709/2018, suas alterações e regulamentações posteriores, durante o cumprimento do objeto descrito no presente instrumento contratual. \n', alignment: 'justify', fontSize: 11 },
      // { text: 'Parágrafo 1°. A INSTITUIÇÃO DE ENSINO obriga-se a adotar medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito de toda informação, dados pessoais e/ou base de dados a que tenha acesso em razão da presente relação contratual.  \n', alignment: 'justify', fontSize: 11 },
      // { text: 'Parágrafo 2°. A INSTITUIÇÃO DE ENSINO deve assegurar-se de que todos os seus colaboradores, consultores e/ou prestadores de serviços que, no exercício das suas atividades, tenham acesso e/ou conhecimento da informação e/ou dos dados pessoais, respeitem o dever de proteção, confidencialidade e sigilo.  \n', alignment: 'justify', fontSize: 11 },
      // { text: `Parágrafo 3°. A INSTITUIÇÃO DE ENSINO poderá realizar o tratamento de informação, dados pessoais e/ou base de dados a que tenha acesso, exclusivamente para fins estabelecidos pela ${data.company_name}, controlador, nos moldes da Lei 13.709/2018.\n`, alignment: 'justify', fontSize: 11 },
      // { text: 'Parágrafo 4°. A INSTITUIÇÃO DE ENSINO não poderá disponibilizar e/ou transmitir a terceiros, sem prévia autorização escrita, informação, dados pessoais e/ou base de dados a que tenha acesso em razão do cumprimento do objeto deste instrumento contratual.\n', alignment: 'justify', fontSize: 11 },
      // { text: `Parágrafo 5°. O dever de sigilo e confidencialidade, e as demais obrigações descritas na presente cláusula, permanecerão em vigor após a extinção das relações entre a INSTITUIÇÃO DE ENSINO e a ${data.company_name}, bem como, entre a INSTITUIÇÃO DE ENSINO e os seus colaboradores, subcontratados, consultores e/ou prestadores de serviços sob pena das sanções previstas na Lei 13.709/2018, suas alterações e regulamentações posteriores, salvo decisão judicial contrária. \n`, alignment: 'justify', fontSize: 12 },
      // { text: 'Parágrafo 6°. O não cumprimento de quaisquer das obrigações descritas nesta cláusula sujeitará a INSTITUIÇÃO DE ENSINO a processo administrativo para apuração de responsabilidade e, consequente, sanção, sem prejuízo de outras cominações cíveis e penais.', alignment: 'justify', fontSize: 11 },

      // { text: 'DA CERTIFICAÇÃO \n', alignment: 'justify', fontSize: 12, bold: true },
      // { text: 'Ao concluir a carga horária acima citada, o aluno receberá certificado emitido conforme DECRETO Nº 5.154, DE 23 DE JULHO DE 2004 e válido em todo território nacional.', alignment: 'justify', fontSize: 11 },

      // { text: 'DAS DISPOSIÇÕES GERAIS\n', alignment: 'justify', fontSize: 12, bold: true },

      // { text: 'As partes declaram que este instrumento da parceria está sendo celebrado por livre e espontânea vontade, de boa fé, e que se propõem a continuar durante a execução.\n', alignment: 'justify', fontSize: 11, },
      // { text: 'A escola poderá colocar em seu portifólio logomarca da SEGUNDA CONVENENTE em site e em suas redes sociais com fins de divulgação da parceria.\n', alignment: 'justify', fontSize: 11, },
      // { text: 'E, por estarem, assim, cientes e conformes, as partes firmam o presente termo de estabelecimento de parceria, em 02 (duas) vias de igual teor e forma, para que produza seus regulares efeitos. \n', alignment: 'justify', fontSize: 11, },
      {
        text: [
          `Pacajus/Ce, ${dataDoConvenio}\n\n\n`,
        ], margin: [20, 10, 20, 20], alignment: 'right', fontSize: 12
      },

      {
        columns: [
          // {
          //   width: '*',

          //   stack: [
          //     {
          //       width: 120,
          //       image: ASSINATURA_MARIA,
          //       alignment: 'center',
          //       relativePosition: { x: 0, y: -5 }
          //     },
          //   ]
          // },
          {
            width: '*',
            text: [
              ''
            ]

          },

        ]
      },

      {
        columns: [
          {
            width: '*',
            text: [
              '___________________________________________________\n',
              `Instituto Goreth Tavares\n`,
              `CNPJ: 11.807.608/0001-49\n`,
            ], alignment: 'center', fontSize: 10

          },
          {
            width: '*',
            text: [
              '___________________________________________________\n',
              `${data.company_name}\n`,
              `CNPJ: ${data.cnpj}\n`,

            ], alignment: 'center', fontSize: 10

          },

        ],

      },


    ], defaultStyle: {
      fontSize: 9,
      margin: [20, 50]
    }
  };

  pdfMake.createPdf(docDefinition).open();
}


export const handleGenerateTermoAnexo = (data) => {

  const dataDoConvenio = data.agreement_start_date ? format(parseISO(data.agreement_start_date.substring(0,10)), "dd 'de' MMMM 'de' yyyy", { locale: ptBR }) : '';

  const valorDinheiro = new Intl.NumberFormat('pt', {
    style: 'currency',
    currency: 'BRL'
  }).format(data.institution_value);


  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 60, 40, 60],
    /*
    header: {
      image: IGT_LOGO,
      width: 120,
      height: 43,
      alignment: 'center',
      margin: 10
    },*/
    content: [
      { text: `ANEXO AO TERMO DE CONVÊNIO\n ACORDO DE VALORES EDUCACIONAIS\n INSTITUIÇÃO DE ENSINO E EMPRESA\n\n`, alignment: 'center', fontSize: 13, bold: true },



      {
        text: [
          `Na condição de Unidade Concedente, ${data.company_name},  pagará mensalmente à instituição de ensino Instituto Goreth Tavares, o valor de R$ ${valorDinheiro} por cada aluno que esteja estagiando e realizando os cursos de qualificação profissional, em decorrência do presente convênio. \n\n`
        ],
        alignment: 'justify',
        fontSize: 11,
        //bold: true
      },

      {
        text: [
          `Parágrafo 1º - Os valores dessas contribuições destinam-se à consecução dos objetivos políticos-pedagógicos da unidade de ensino, à cobertura dos custos técnicos e administrativos, colaborando ativamente para a preparação profissional do estudante. \n\n`
        ],
        alignment: 'justify',
        fontSize: 11,
        //bold: true
      },

      {
        text: [
          `Parágrafo 2º - Esse valor deverá ser pago mensalmente a instituição de ensino em relação a cada aluno, por meio de BOLETO BANCÁRIO/PIX, com envio eletrônico de nota fiscal. A Unidade Concedente efetuará o pagamento até o dia 5 (Cinco) do mês subsequente.  \n\n`
        ],
        alignment: 'justify',
        fontSize: 11,
        //bold: true
      },

      {
        text: [
          `Parágrafo 3º - Na hipótese de atraso do pagamento da receita institucional poderá a instituição de ensino a qualquer tempo e a seu exclusivo critério promover a ação judicial cabível em face da Unidade Concedente para o cumprimento da obrigação contraída, bem como promover o cancelamento do presente Convênio. \n\n`
        ],
        alignment: 'justify',
        fontSize: 11,
        //bold: true
      },

      {
        text: [
          `Parágrafo 4º - O valor da contribuição mencionado será reajustado a cada 12 (doze) meses, contado a partir da data de sua vigência, pelo INPC (Índice nacional de preço ao consumidor). \n\n`
        ],
        alignment: 'justify',
        fontSize: 11,
        //bold: true
      },


      {
        text: [
          `Pacajus/Ce, ${dataDoConvenio}\n\n\n`,
        ], margin: [20, 10, 20, 20], alignment: 'right', fontSize: 12
      },

      {
        columns: [
          // {
          //   width: '*',

          //   stack: [
          //     {
          //       width: 120,
          //       image: ASSINATURA_MARIA,
          //       alignment: 'center',
          //       relativePosition: { x: 0, y: -5 }
          //     },
          //   ]
          // },
          {
            width: '*',
            text: [
              ''
            ]

          },

        ]
      },

      {
        columns: [
          {
            width: '*',
            text: [
              '___________________________________________________\n',
              `Instituto Goreth Tavares\n`,
              `CNPJ: 11.807.608/0001-49\n`,
            ], alignment: 'center', fontSize: 10

          },
          {
            width: '*',
            text: [
              '___________________________________________________\n',
              `${data.company_name}\n`,
              `CNPJ: ${data.cnpj}\n`,

            ], alignment: 'center', fontSize: 10

          },

        ],

      },


    ], defaultStyle: {
      fontSize: 9,
      margin: [20, 50]
    }
  };

  pdfMake.createPdf(docDefinition).open();
}



export const handleGenerateContractCompanyTwo = (data) => {

  const dataDoConvenio = data.agreement_start_date ? format(parseISO(data.agreement_start_date.substring(0,10)), "dd 'de' MMMM 'de' yyyy", { locale: ptBR }) : '';

  const valorAgenteIntegração = new Intl.NumberFormat('pt', {
    style: 'currency',
    currency: 'BRL'
  }).format(data.integration_agent_value);

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 80, 40, 60],
    
   header: {
     image: LOGO_DR_ESTAGIO,
     width: 140,
     height: 43,
     alignment: 'center',
     margin: 20
   },
   
    content: [
      { text: 'TERMO DE CONVÊNIO DE PARCERIA – UNIDADE CONCEDENTE', alignment: 'center', fontSize: 12, margin: [0, 0, 0, 10], bold: true },

       
      {
        text: [
          'Pelo presente instrumento, a ',
        
          { text: 'instituição DR. EStÁGIO SOLUÇÕES TECNOLÓGICAS PARA VAREJO LTDA ', bold: true },
          'localizada na Av. Desembargador Moreira, 2020 - Aldeota - Fortaleza - CE, inscrita no CNPJ sob o nº 23.760.412/0001-25, doravante denominada',
         
          { text: 'AGENTE DE INTEGRAÇÃO, ', bold: true },
          ' neste ato representado por seu Diretor: Eider Alves Pereira e ',
          { text: `${data.company_name}`, bold: true },
          ' estabelecido na ',
          { text: `${data.address},` },
          { text: ` Nº ${data.number} - ` },
          { text: `${data.neighborhood} - ` },
          { text: `${data.city} - ` },
          { text: `${data.state},` },
          ' inscrito no CNPJ sob o nº: ',
          { text: `${data.cnpj},` },
          ' doravante denominado ',
          { text: 'UNIDADE CONCEDENTE,', bold: true },
          ' neste ato convencionam, entre si, o presente convênio que se regerá pelas cláusulas e condições seguintes: estabelecido na: \n\n'

        ], alignment: 'justify'

      },
      { text: 'CLÁUSULA 1ª - Este Convênio tem por fim estabelecer as condições gerais que viabilize a realização de Programas de Estágios para Estudantes, nos termos do que dispõe a Lei nº. 11.788 de 25 de Setembro de 2008.\n', alignment: 'justify' },
      { text: 'CLÁUSULA 2ª - O Estágio para Estudantes de Cursos do Ensino Superior e de Cursos de Educação Profissional, de Cursos de Nível Médio, de interesse curricular, obrigatório ou não, caracteriza- se como a possibilidade do estudante ser colocado em situação real de vida e de trabalho, junto a pessoas jurídicas de direito público e privado, em atividades de aprendizagem social, profissional e cultural compatíveis com os respectivos Projetos Pedagógicos, de acordo com o disposto na Lei n°. 11.788 de 25de Setembro de 2008.\n', alignment: 'justify' },
      { text: 'CLÁUSULA 3ª - O estágio de estudantes realizados em conformidade com as disposições e interveniência das Instituições de Ensino, com o desenvolvimento das atividades programadas e documentação específica em ordem, não acarretarão vinculaçãoempregatícia entre as partes, nos termos do que expressamente prescreve o art. 3º da Lei n° 11.788 de 25 de Setembro de 2008.Parágrafo único - A concessão de Bolsa- Estágio ao Estudante, por si só, não descaracterizará a natureza do estágio nem produzirá vinculação empregatícia.\n', alignment: 'justify' },
      { text: 'CLÁUSULA 4ª - Para cumprir o estabelecido na Cláusula 1ª, e em conformidade com o disposto no art. 5º da Lei n° 11.788/08, caberá ao DR:\n', alignment: 'justify' },
      { text: 'ESTÁGIO em seu papel de Agente de Integração e órgão de apoio à Unidade Concedente:\n', alignment: 'justify' },
      { text: 'a) Relacionar- se com as Instituições de Ensino, firmando Convênios com a especificação das condições e requisitos mínimos exigidos para caracterização e definição dos Estágios de seus alunos;', alignment: 'justify' },
      { text: 'b) Obter da Unidade Concedente a quantificação das Oportunidades de Estágio a serem preenchidas, com a identificação detodos os pormenores pertinentes;\n', alignment: 'justify' },
      { text: 'c) Promover o ajuste das condições de estágio, conciliando os requisitos mínimos exigidos pelas Instituições de Ensino com as disponibilidades da Unidade Concedente;\n', alignment: 'justify' },
      { text: 'd) Providenciar o respectivo Termo de Compromisso de Estágio, instrumento jurídico de que trata o art. 16 da Lei n° 11.788/08, colhendo as assinaturas da Unidade Concedente e da Instituição de Ensino.\n', alignment: 'justify' },
      { text: 'e) Após fechamento da parceria em comum acordo com a unidade concedente de acordo com Art. 5°, inciso 1° item IV e V a contratação e pagamento do seguro de vida fica sob responsabilidade da Dr. Estágio Parágrafo único: Em caso de rescisão do presente convênio entre a unidade concedente e a DR. ESTÁGIO, os estagiários permanecerão assegurados por um período de 30 dias após o encerramento do convênio. Passado este período, a unidade concedente ficará responsável pela negociação, contratação e inserção dos seus estagiários no seguro de vida. Eximindo a DR. ESTÁGIO de qualquer responsabilidade por incidentes que venham a ocorrer após o fim deste período. \n', alignment: 'justify' },
      { text: 'f) Acompanhar o desenvolvimento dos estágios contratados, através de relatórios semestrais e/ ou de visitas aos locais dos estágios, transferindo os resultados às Instituições de Ensino;\n', alignment: 'justify' },

      { text: 'CLÁUSULA 5ª - Para cumprir o estabelecido na Cláusula 1ª, caberá à Unidade Concedente: \n', alignment: 'justify' },
      { text: 'a) Instruir os supervisores dos estagiários sobre o Convênio existente com a DR. ESTÁGIO e sobre as condições do Programa de Estágio a ser cumprido;\n', alignment: 'justify' },
      { text: 'b) De acordo com o inciso III, do art. 9° da Lei 11.788/2008, o supervisor do estagiário da parte da unidade concedente deve ser funcionário do seu quadro de pessoal com formação ou experiencia na área do estágio, podendo orientar e supervisionar até 10 (dez) estagiários simultaneamente.\n', alignment: 'justify' },
      { text: 'c) Identificar, qualificar e quantificar as oportunidades de estágio a serem concedidas, atendendo as condições e requisitos mínimos dos estágio a ser cumprido;\n', alignment: 'justify' },
      { text: 'd) Formalizar as oportunidades de estágio a DR. ESTÁGIO a partir do formulário de Solicitação de Candidatos;\n', alignment: 'justify' },
      { text: 'e) Receber os estudantes-candidatos encaminhados por meio da DR. ESTÁGIO, estabelecendo o valor da Bolsa-Estágio e mantendo com os mesmos os últimos entendimentos sobre as condições de realização dos estágios;\n', alignment: 'justify' },
      { text: 'f) Informar a DR. ESTÁGIO os nomes dos estudantes que deverão ser contratados para a realização de estágios;\n', alignment: 'justify' },
      { text: 'g) Celebrar com a Instituição de Ensino o Termo de Ajuste de Cooperação (art. 16 da Lei n° 11.788/08), preparado por meio da DR. ESTÁGIO;\n', alignment: 'justify' },
      { text: 'h) Firmar com o estudante o respectivo Termo de Compromisso de Estágio preparado pelo DR. ESTÁGIO;\n', alignment: 'justify' },
      { text: 'i) Exigir dos supervisores dos estagiários que os Programas de Estágio sejam cumpridos conforme planejado;\n', alignment: 'justify' },
      { text: 'j) Através de prepostos previamente indicados, participarem da sistemática de acompanhamento, supervisão e avaliação dos estágios, fornecendo, quando for o caso, por meio da DR. ESTÁGIO, informações às Instituições de Ensino;', alignment: 'justify' },
      { text: 'k) Informar mensalmente a DR. ESTÁGIO a frequência dos estudantes ao estágio;\n', alignment: 'justify' },
      { text: 'l) Informar a DR. ESTÁGIO, por escrito (por meio de carta com aviso de recebimento ou e-mail), de forma imediata:\n', alignment: 'justify' },
      { text: '1. A inclusão de novos estagiários, para as providências necessárias;\n', alignment: 'justify', margin: [20, 0, 0, 0] },
      { text: '2. Os desligamentos de seus estagiários, sob pena de pagar a taxa administrativa referente até o momento em que a DR. ESTÁGIO for informado do desligamento.\n', alignment: 'justify', margin: [20, 0, 0, 0] },
      { text: '3. A efetivação do estagiário durante a vigência do Termo de Compromisso de Estágio, sob pena de pagar a taxa administrativa até o momento em que a DR. ESTÁGIO for informada da efetivação;\n', alignment: 'justify', margin: [20, 0, 0, 0] },
      { text: '4. Os termos citados acima, mediante sistema, será confeccionado no dia informado a DR. ESTÁGIO, não podendo ser efetuado nenhum documento com datas retroativas;\n', alignment: 'justify', margin: [20, 0, 0, 0] },

      { text: 'l) Efetuar o pagamento da Bolsa-Estágio mensal e Auxílio Transporte combinada aos seus Estagiários, especificada no Termo de Compromisso de Estágio;\n', alignment: 'justify' },
      { text: 'm) A inadimplência, por parte da Unidade Concedente, do pagamento do valor mensal das taxas administrativa (conforme cláusula sétima), implicará o acréscimo de mora de 2,0% e juros legais no patamar de 0,33% ao dia.', alignment: 'justify' },

      {
        text: [
          ' CLÁUSULA 6ª - Na condição de Unidade Concedente pagará mensalmente a DR. ESTÁGIO o valor de ',
          { text: `${valorAgenteIntegração}`, bold: true },
          { text: ` por cada estudante que estiver realizando estágio em suas dependências em decorrência do presente Convênio, a ser recebido como Taxa Administrativa para cobertura de gastos operacionais. O valor da contribuição mencionado será reajustado anualmente, contado a partir da data de sua vigência, pelo INPC (Índice nacional de preço ao consumidor). `, alignment: 'justify' },
        ],
      },

      { text: 'Parágrafo 1º - Na hipótese de atraso do pagamento da receita institucional poderá a DR. ESTÁGIO a qualquer tempo e a seu exclusivo critério promover a ação judicial cabível em face da Unidade Concedente para o cumprimento da obrigação contraída, bem como promover o cancelamento do presente Convênio.\n', alignment: 'justify' },

      { text: 'Parágrafo 2º - Esse valor deverá ser pago mensalmente a DR. ESTÁGIO, por meio de Boleto Bancário, com envio eletronico de nota fiscal em relação a cada aluno-estagiário, a Unidade Concedente efetuará até o dia 5 (Cinco) do mês subsequente.', alignment: 'center' },

      { text: 'Parágrafo 3º - Os valores dessas contribuições destinam-se à consecução dos objetivos políticos-pedagógicos da DR. ESTÁGIO, à cobertura dos custos técnicos - administrativos - operacionais e seguro;\n', alignment: 'justify' },


      { text: 'CLÁUSULA 7ª - A DR. ESTÁGIO, no âmbito do que permite seu Contrato Social, poderá executar outros projetos de interesse da Unidade Concedente, se está assim o desejar, mediante prévios entendimentos; \n', alignment: 'justify' },

      { text: 'CLÁUSULA 8ª - CLÁUSULAS QUE DECORREM DA LEI GERAL DE PROTEÇÃO DE DADOS\n', alignment: 'justify' },
      { text: 'CLÁUSULA 9ª - O AGENTE DE INTEGRAÇÃO obriga-se ao dever de proteção, confidencialidade e sigilo de toda informação, dados pessoais e/ou base de dados a que tenha acesso por meios físicos ou eletrônicos em razão da operação, nos termos da Lei 13.709/2018, suas alterações e regulamentações posteriores, durante o cumprimento do objeto descrito no presente instrumento contratual.\n', alignment: 'justify' },

      { text: 'Parágrafo 1°. O AGENTE DE INTEGRAÇÃO obriga-se a adotar medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito de toda informação, dados pessoais e/ou base de dados a que tenha acesso em razão da presente relação contratual.\n', alignment: 'justify' },
      { text: 'Parágrafo 2°. O AGENTE DE INTEGRAÇÃO deve assegurar-se de que todos os seus colaboradores, consultores e/ou prestadores de serviços que, no exercício das suas atividades, tenham acesso e/ou conhecimento da informação e/ou dos dados pessoais, respeitem o dever de proteção, confidencialidade e sigilo.\n', alignment: 'justify' },
      { text: `Parágrafo 3°. O AGENTE DE INTEGRAÇÃO poderá realizar o tratamento de informação, dados pessoais e/ou base de dados a que tenha acesso, exclusivamente para fins estabelecidos pela ${data.company_name}, controlador, nos moldes da Lei 13.709/2018.\n`, alignment: 'justify' },
      { text: `Parágrafo 4°. O AGENTE DE INTEGRAÇÃO não poderá disponibilizar e/ou transmitir a terceiros, sem prévia autorização escrita, informação, dados pessoais e/ou base de dados a que tenha acesso em razão do cumprimento do objeto deste instrumento contratual.\n`, alignment: 'justify' },
      { text: `Parágrafo 5°. O dever de sigilo e confidencialidade, e as demais obrigações descritas na presente cláusula, permanecerão em vigor após a extinção das relações entre o AGENTE DE INTEGRAÇÃO e a ${data.company_name}, bem como, entre o AGENTE DE INTEGRAÇÃO e os seus colaboradores, subcontratados, consultores e/ou prestadores de serviços sob pena das sanções previstas na Lei 13.709/2018, suas alterações e regulamentações posteriores, salvo decisão judicial contrária.\n`, alignment: 'justify' },
      { text: 'Parágrafo 6°. O não cumprimento de quaisquer das obrigações descritas nesta cláusula sujeitará o AGENTE DE INTEGRAÇÃO a processo administrativo para apuração de responsabilidade e, consequente, sanção, sem prejuízo de outras cominações cíveis e penais.\n', alignment: 'justify' },

      { text: 'CLÁUSULA 10ª - O presente convênio terá vigência por prazo indeterminado, a partir da data de sua assinatura, podendo as partes, a qualquer tempo, sem quaisquer ônus ou multa, promover a resilição do presente contrato, devendo a parte contrária ser comunicada com antecedência mínima de 30 (trinta) dias. Em caso de descumprimento contratual, poderão as partes, independentemente de aviso prévio, promover a imediata extinção do contrato, sem prejuízo da apuração de eventuais perdas e danos causados pela parte infratora.\n', alignment: 'justify' },

      { text: 'CLÁUSULA 11ª - As partes contratantes elegem o foro da Comarca de Fortaleza - Ceará, para dirimir quaisquer dúvidas oriundasdeste presente contrato.\n', alignment: 'justify' },

      {
        text: [
          `Fortaleza/Ce, ${dataDoConvenio} \n\n`,
        ], margin: [20, 10, 20, 20], alignment: 'right',
      },
     
      {
        columns: [
          {
            width: '*',
            text: [
              '___________________________________________________\n',
              `${data.company_name}\n`,
              `CNPJ: ${data.cnpj}\n\n`,
            ], alignment: 'center'

          },
          {
            width: '*',
            text: [
              '___________________________________________________\n',
              `DR. ESTÁGIO\n`,
              `CNPJ: 23.760.412/0001-25\n\n`,
            ], alignment: 'center',

          },
        ],

      },

    ], defaultStyle: {
      fontSize: 9,
      margin: [20, 50]
    }
  };

  pdfMake.createPdf(docDefinition).open();
}

export const handleGenerateReportActivesContract = (data, title = "Contratos", omitValue) => {
  const sortedData = data.sort(compareTraineeName);

  const dataPdf = sortedData.map(item => {
    return [
      { text: item.trainee_name?.toUpperCase(), alignment: 'center', fontSize: 9 },
      { text: item.trainee_primary_phone_contact, alignment: 'center', fontSize: 9 },
      { text: item.company_name?.toUpperCase(), alignment: 'center', fontSize: 9 },
      { text: item.insurance_number, alignment: 'center', fontSize: 9 }
    ];
  });

  const totalAgentValue = data.reduce((acumulador, elemento) => acumulador += elemento.integration_agent_value_company, 0);

  const totalAgentValueFormatted = new Intl.NumberFormat('pt', {
    style: 'currency',
    currency: 'BRL'
  }).format(totalAgentValue);


  const totalInstituionValue = data.reduce((acumulador, elemento) => acumulador += elemento.institution_value_company, 0);
  const totalInstituionValueFormatted = new Intl.NumberFormat('pt', {
    style: 'currency',
    currency: 'BRL'
  }).format(totalInstituionValue);


  const columns = 
    [
      { text: 'Estagíario', bold: true, alignment: 'center' },
      { text: 'Contato', bold: true, alignment: 'center' },
      { text: 'Empresa', bold: true, alignment: 'center' },
      { text: 'Nº do seguro', bold: true, alignment: 'center' },
    ];

  const widths = ['*', '*', '*', 100];

  const extrasInfos = !omitValue.value ? [
    { text: `\n\n Total do agente de integração: ${totalAgentValueFormatted} `, fontSize: 14, margin: [0, 0, 0, 10] },
    { text: `Total taxa escola: ${totalInstituionValueFormatted} \n`, fontSize: 14, margin: [0, 0, 0, 10] },
  ] : [];

  const docDefinition = {
    pageMargins: [40, 90, 40, 50],
    /*
   header: {
     image: LOGO_DR_ESTAGIO,
     width: 140,
     height: 43,
     alignment: 'center',
     margin: 20
   },*/
    content: [

      { text: title, alignment: 'center', fontSize: 16, bold: true, margin: [0, 0, 0, 20] },
      {
        layout: {
          fillColor: function (rowIndex) {
            return (rowIndex % 2 === 0) ? '#F8F7FE' : null;
          }
        },
        table: {
          headerRows: 1,
          heights: 28,
          widths,

          body: [
            columns,
            ...dataPdf
          ]
        },


      },
      ...extrasInfos,
      { text: `Total de estagiários: ${data.length} \n`, fontSize: 14, margin: [0, 0, 0, 10] },


    ], defaultStyle: {
      fontSize: 12,
      margin: [20, 50]
    }
  };

  pdfMake.createPdf(docDefinition).open();
}

export const handleGenerateReportFinancial = (data, title = "RELATÓRIO FINANCEIRO", omitValue, showData) => {

  console.log(data)
  const applyRuleIsHigherEducation = (isHigherEducation, value) => (isHigherEducation ? "Sim" : "Não");

  let parseData = data;

  if (showData.value === "ATIVO") {
    parseData = parseData.filter((item) => item.status === showData.value);
  }
  if (showData.value === "INATIVO") {
    parseData = parseData.filter((item) => item.status === showData.value);
  }

  const sortedData = parseData.sort(compareTraineeName);
  const nomeEmpresa = [...new Set(sortedData.map((item) => item.company_name))];

  const dataPdf = sortedData.map((item) => {
    return [
      { text: item.status, alignment: "center", fontSize: 9 },
      { text: item.institution_value_company_total?.toFixed(2), alignment: "center", fontSize: 9 },
      { text: item.integration_agent_value_company_total?.toFixed(2), alignment: "center", fontSize: 9 },
      { text: item.trainee_name?.toUpperCase(), alignment: "center", fontSize: 9 },
      { text: item.insurance_number, alignment: "center", fontSize: 9 },
      { text: applyRuleIsHigherEducation(item.isHigherEducation, item.value), alignment: "center", fontSize: 9 },
    ];
  });

  const totalAgentValue = data.reduce((acc, el) => acc + el.integration_agent_value_company_total, 0);
  const totalInstitutionValue = data.reduce((acc, el) => acc + el.institution_value_company_total, 0);
  const totalFinal = totalAgentValue + totalInstitutionValue;

  const totalAgentValueFormatted = new Intl.NumberFormat("pt", { style: "currency", currency: "BRL" }).format(totalAgentValue);
  const totalInstitutionValueFormatted = new Intl.NumberFormat("pt", { style: "currency", currency: "BRL" }).format(totalInstitutionValue);
  const totalFinalFormatted = new Intl.NumberFormat("pt", { style: "currency", currency: "BRL" }).format(totalFinal);

  const columns = [
    { text: "Status", bold: true, alignment: "center" },
    { text: "Val Esc Cobrado", bold: true, alignment: "center" },
    { text: "Ag Integ Cobrado", bold: true, alignment: "center" },
    { text: "Estagiário", bold: true, alignment: "center" },
    { text: "Nº do Seguro", bold: true, alignment: "center" },
    { text: "Ensino Superior?", bold: true, alignment: "center" },
  ];

  const widths = [50, 50, 50, "*", 50, 60];

  const totalsSection = [
    {
      table: {
        widths: ["*", "*"],
        body: [
          [{ text: "Totais", bold: true, fontSize: 14, colSpan: 2, alignment: "center" }, ""],
          [{ text: "Total do Agente de Integração:", alignment: "right" }, totalAgentValueFormatted],
          [{ text: "Total Taxa Escola:", alignment: "right" }, totalInstitutionValueFormatted],
          [{ text: "Total Geral:", alignment: "right", bold: true }, totalFinalFormatted],
          [{ text: "Total de Estagiários:", alignment: "right" }, `${data.length}`],
        ],
      },
      layout: "lightHorizontalLines",
      margin: [0, 20, 0, 0],
    },
  ];

  const docDefinition = {
    pageMargins: [40, 90, 40, 50],
    content: [
      { text: title, alignment: "center", fontSize: 16, bold: true, margin: [0, 0, 0, 10] },
      { text: nomeEmpresa, alignment: "center", fontSize: 14, bold: true, margin: [0, 0, 0, 10] },
      {
        layout: {
          fillColor: (rowIndex) => (rowIndex % 2 === 0 ? "#F8F7FE" : null),
        },
        table: {
          headerRows: 1,
          heights: 28,
          widths,
          body: [columns, ...dataPdf],
        },
      },
      ...totalsSection,
    ],
    defaultStyle: {
      fontSize: 10,
    },
  };

  pdfMake.createPdf(docDefinition).open();
};


export const handleGenerateReportAttendance = (data, title = "RELATÓRIO DE PRESENÇAS", order) => {
  const sortedData = order.value === 'name' ? data.sort(compareTraineeName) : data.sort(compareDate);

  const dataPdf = sortedData.map(item => {
    return [
      { text: item.type, alignment: 'center', fontSize: 9 },
      { text: item.name?.toUpperCase(), alignment: 'center', fontSize: 9 },
      { text: item.primary_phone_contact, alignment: 'center', fontSize: 9 },
      { text: item.company_name?.toUpperCase(), alignment: 'center', fontSize: 9 },
      { text: item.date, alignment: 'center', fontSize: 9 }
    ];
  });


  const columns =
    [
      { text: '#', bold: true, alignment: 'center' },
      { text: 'Estagiário', bold: true, alignment: 'center' },
      { text: 'Telefone', bold: true, alignment: 'center' },
      { text: 'Empresa', bold: true, alignment: 'center' },
      { text: 'date', bold: true, alignment: 'center' },
    ];
  const widths = ['auto', '*', '*', 'auto', 'auto'];

  const docDefinition = {
    pageMargins: [40, 90, 40, 50],
    /*
   header: {
     image: IGT_LOGO,
     width: 140,
     height: 43,
     alignment: 'center',
     margin: 20
   },*/
    content: [
      { text: title, alignment: 'center', fontSize: 16, bold: true, margin: [0, 0, 0, 20] },
      {
        layout: {
          fillColor: function (rowIndex) {
            return (rowIndex % 2 === 0) ? '#F8F7FE' : null;
          }
        },
        table: {
          headerRows: 1,
          heights: 28,
          widths,

          body: [
            columns,
            ...dataPdf
          ]
        },


      },
      { text: `Total de registros: ${data.length} \n`, fontSize: 14, margin: [0, 0, 0, 20] },


    ], defaultStyle: {
      fontSize: 10,
      margin: [20, 50]
    }
  };

  pdfMake.createPdf(docDefinition).open();
}

export const handleGenerateReportAttendanceCount = (data, title = "RELATÓRIO DE PRESENÇAS") => {
  const sortedData = data.sort(compareTraineeName)

  const dataPdf = sortedData.map(item => {
    return [
      { text: item.date, alignment: 'center', fontSize: 9 },
      { text: item.trainee_name?.toUpperCase(), alignment: 'center', fontSize: 9 },
      { text: item.primary_phone_contact, alignment: 'center', fontSize: 9 },
      { text: item.type, alignment: 'center', fontSize: 9 },
      { text: item.count, alignment: 'center', fontSize: 9 }
    ];
  });


  const columns =
    [
      { text: 'Data', bold: true, alignment: 'center' },
      { text: 'Estagiário', bold: true, alignment: 'center' },
      { text: 'Telefone', bold: true, alignment: 'center' },
      { text: 'Tipo', bold: true, alignment: 'center' },
      { text: 'Qtd', bold: true, alignment: 'center' },

    ];
  const widths = ['auto', 'auto', '*', 'auto', 'auto'];

  const docDefinition = {
    pageMargins: [40, 90, 40, 50],
    /*
   header: {
     image: IGT_LOGO,
     width: 140,
     height: 43,
     alignment: 'center',
     margin: 20
   },*/
    content: [
      { text: title, alignment: 'center', fontSize: 16, bold: true, margin: [0, 0, 0, 20] },
      {
        layout: {
          fillColor: function (rowIndex) {
            return (rowIndex % 2 === 0) ? '#F8F7FE' : null;
          }
        },
        table: {
          headerRows: 1,
          heights: 28,
          widths,

          body: [
            columns,
            ...dataPdf
          ]
        },


      },
      { text: `Total de registros: ${data.length} \n`, fontSize: 14, margin: [0, 0, 0, 20] },


    ], defaultStyle: {
      fontSize: 10,
      margin: [20, 50]
    }
  };

  pdfMake.createPdf(docDefinition).open();
}



export const handleGenerateReportInactivesContract = (data, title = "Relatório de desligamentos", omitValue) => {
  const sortedData = data.sort(compareTraineeName);

  const totalAgentValue = data.reduce((acumulador, elemento) => acumulador += elemento.integration_agent_value_company, 0);

  const totalAgentValueFormatted = new Intl.NumberFormat('pt', {
    style: 'currency',
    currency: 'BRL'
  }).format(totalAgentValue);

  const totalInstituionValue = data.reduce((acumulador, elemento) => acumulador += elemento.institution_value_company, 0);
  const totalInstituionValueFormatted = new Intl.NumberFormat('pt', {
    style: 'currency',
    currency: 'BRL'
  }).format(totalInstituionValue);

  const extrasInfos = omitValue && !omitValue.value ? [
    { text: `\n\n Total do agente de integração: ${totalAgentValueFormatted} `, fontSize: 14, margin: [0, 0, 0, 10] },
    { text: `Total taxa escola: ${totalInstituionValueFormatted} \n`, fontSize: 14, margin: [0, 0, 0, 10] },
  ] : [];

  const dataPdf = sortedData.map(item => {
    if (omitValue && omitValue.value) {
      return [
        item.trainee_name?.toUpperCase(),
        item.trainee_primary_phone_contact,
        item.company_name.toUpperCase(),
        item.date_shutdown
      ];
    }
    return [
      item.trainee_name?.toUpperCase(),
      item.trainee_primary_phone_contact,
      item.company_name?.toUpperCase(),
      item.date_shutdown,
      item.insurance_number,

    ];
  });

  const columns = omitValue && !omitValue.value ? ['Estágiario', 'Contato', 'Empresa', 'Desligado em', 'Nº seguro'] : ['Estágiario', 'Contato', 'Empresa', 'Desligado em'];
  const widths = omitValue && !omitValue.value ? ['*', '*', '*', 100, 100] : ['*', '*', '*', 100];

  const docDefinition = {
    pageMargins: [40, 90, 40, 50],
    /*
   header: {
     image: LOGO_DR_ESTAGIO,
     width: 140,
     height: 43,
     alignment: 'center',
     margin: 20
   },*/
    content: [

      { text: title, alignment: 'center', fontSize: 16, margin: [0, 0, 0, 20] },
      {

        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex % 2 === 0) ? '#F8F7FE' : null;
          }
        },
        table: {
          headerRows: 1,
          heights: 28,
          widths,
          body: [
            columns,
            ...dataPdf
          ]
        },
      },
      ...extrasInfos,
      { text: `\n Total de estagiários: ${data.length} \n`, fontSize: 14, margin: [0, 0, 0, 10] },

    ], defaultStyle: {
      fontSize: 10,
      margin: [20, 50]
    }
  };

  pdfMake.createPdf(docDefinition).open();
}



export const handleGenerateReportAttendanceEachTrainne = (name, data) => {

  const dataPdf = data.map(item => {
    return [
      { text: item.cpf, alignment: 'center', fontSize: 9 },
      { text: item.date, alignment: 'center', fontSize: 9 },
      { text: item.type, alignment: 'center', fontSize: 9 }
    ];
  });

  const columns =
    [
      { text: 'CPF', bold: true, alignment: 'center' },
      { text: 'Data', bold: true, alignment: 'center' },
      { text: 'TIPO', bold: true, alignment: 'center' },
    ];
  const widths = ['auto', '*', '*'];

  const docDefinition = {
    pageMargins: [40, 90, 40, 50],
    /*
   header: {
     image: LOGO_DR_ESTAGIO,
     width: 140,
     height: 43,
     alignment: 'center',
     margin: 20
   },*/
    content: [
      { text: `PRESENÇAS - ${name}`, alignment: 'center', fontSize: 16, bold: true, margin: [0, 0, 0, 20] },
      {
        layout: {
          fillColor: function (rowIndex) {
            return (rowIndex % 2 === 0) ? '#F8F7FE' : null;
          }
        },
        table: {
          headerRows: 1,
          heights: 28,
          widths,

          body: [
            columns,
            ...dataPdf
          ]
        },


      },
      { text: `Total de registros: ${data.length} \n`, fontSize: 14, margin: [0, 0, 0, 20] },


    ], defaultStyle: {
      fontSize: 10,
      margin: [20, 50]
    }
  };

  pdfMake.createPdf(docDefinition).open();
}
