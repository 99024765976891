import React, { useState, useEffect } from "react";
import Logo from "../../assets/Images/Logo.svg";
import "./style.css"; // O arquivo CSS para estilizar a tela de carregamento
import { Image } from "react-bootstrap";
import TreeLoader from "src/Events/Natal/Treeloader";

const Loader = ({ isLoading }) => {
  const [isVisible, setIsVisible] = useState(isLoading);

  useEffect(() => {
    if (!isLoading) {
      // Aguardar a animação de fade-out terminar antes de remover o componente
      const timer = setTimeout(() => setIsVisible(false), 1000); // Duração da animação de saída
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  if (!isVisible) return null; // Remove o componente após o fade-out

  return (
    <div className={`loader-overlay ${!isLoading ? "fade-out" : ""}`}>
      <div className="logo-top">
        <Image src={Logo} />
      </div>

      {/* <div className="loader">
        <div className="star-box">
          <svg viewBox="0 0 128 128" height="128" width="128" className="star">
            <defs>
              <filter id="star-glow">
                <feGaussianBlur
                  stdDeviation="1.5"
                  result="coloredBlur"
                ></feGaussianBlur>
                <feMerge>
                  <feMergeNode in="coloredBlur"></feMergeNode>
                  <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
              </filter>
              <linearGradient y2="1" x2="0" y1="0" x1="0" id="star-grad">
                <stop stop-color="#000" offset="0%"></stop>
                <stop stop-color="#fff" offset="100%"></stop>
              </linearGradient>
              <mask id="star-mask">
                <rect
                  fill="url(#star-grad)"
                  height="128"
                  width="128"
                  y="0"
                  x="0"
                ></rect>
              </mask>
            </defs>
            <g
              stroke-width="2"
              stroke-linejoin="round"
              stroke-linecap="round"
              fill="none"
            >
              <g stroke="hsla(223,90%,50%,0.2)">
                <polygon points="64 49 66.322 58.992 71.071 56.929 69.008 61.678 79 64 69.008 66.322 71.071 71.071 66.322 69.008 64 79 61.678 69.008 56.929 71.071 58.992 66.322 49 64 58.992 61.678 56.929 56.929 61.678 58.992 64 49"></polygon>
                <polygon points="64 34 68.644 53.983 78.142 49.858 74.017 59.356 94 64 74.017 68.644 78.142 78.142 68.644 74.017 64 94 59.356 74.017 49.858 78.142 53.983 68.644 34 64 53.983 59.356 49.858 49.858 59.356 53.983 64 34"></polygon>
                <polygon points="64 19 70.966 48.975 85.213 42.787 79.025 57.034 109 64 79.025 70.966 85.213 85.213 70.966 79.025 64 109 57.034 79.025 42.787 85.213 48.975 70.966 19 64 48.975 57.034 42.787 42.787 57.034 48.975 64 19"></polygon>
                <polygon points="64 4 73.287 43.966 92.284 35.716 84.034 54.713 124 64 84.034 73.287 92.284 92.284 73.287 84.034 64 124 54.713 84.034 35.716 92.284 43.966 73.287 4 64 43.966 54.713 35.716 35.716 54.713 43.966 64 4"></polygon>
              </g>
              <g filter="url(#star-glow)">
                <g stroke="hsl(223,90%,50%)">
                  <polygon
                    points="64 49 66.322 58.992 71.071 56.929 69.008 61.678 79 64 69.008 66.322 71.071 71.071 66.322 69.008 64 79 61.678 69.008 56.929 71.071 58.992 66.322 49 64 58.992 61.678 56.929 56.929 61.678 58.992 64 49"
                    stroke-dasharray="31 93"
                    className="star__stroke"
                  ></polygon>
                  <polygon
                    points="64 34 68.644 53.983 78.142 49.858 74.017 59.356 94 64 74.017 68.644 78.142 78.142 68.644 74.017 64 94 59.356 74.017 49.858 78.142 53.983 68.644 34 64 53.983 59.356 49.858 49.858 59.356 53.983 64 34"
                    stroke-dasharray="62 186"
                    className="star__stroke star__stroke--2"
                  ></polygon>
                  <polygon
                    points="64 19 70.966 48.975 85.213 42.787 79.025 57.034 109 64 79.025 70.966 85.213 85.213 70.966 79.025 64 109 57.034 79.025 42.787 85.213 48.975 70.966 19 64 48.975 57.034 42.787 42.787 57.034 48.975 64 19"
                    stroke-dasharray="93 279"
                    className="star__stroke star__stroke--3"
                  ></polygon>
                  <polygon
                    points="64 4 73.287 43.966 92.284 35.716 84.034 54.713 124 64 84.034 73.287 92.284 92.284 73.287 84.034 64 124 54.713 84.034 35.716 92.284 43.966 73.287 4 64 43.966 54.713 35.716 35.716 54.713 43.966 64 4"
                    stroke-dasharray="124 372"
                    className="star__stroke star__stroke--4"
                  ></polygon>
                </g>
                <g mask="url(#star-mask)" stroke="var(--secondary)">
                  <polygon
                    points="64 49 66.322 58.992 71.071 56.929 69.008 61.678 79 64 69.008 66.322 71.071 71.071 66.322 69.008 64 79 61.678 69.008 56.929 71.071 58.992 66.322 49 64 58.992 61.678 56.929 56.929 61.678 58.992 64 49"
                    stroke-dasharray="31 93"
                    className="star__stroke"
                  ></polygon>
                  <polygon
                    points="64 34 68.644 53.983 78.142 49.858 74.017 59.356 94 64 74.017 68.644 78.142 78.142 68.644 74.017 64 94 59.356 74.017 49.858 78.142 53.983 68.644 34 64 53.983 59.356 49.858 49.858 59.356 53.983 64 34"
                    stroke-dasharray="62 186"
                    className="star__stroke star__stroke--2"
                  ></polygon>
                  <polygon
                    points="64 19 70.966 48.975 85.213 42.787 79.025 57.034 109 64 79.025 70.966 85.213 85.213 70.966 79.025 64 109 57.034 79.025 42.787 85.213 48.975 70.966 19 64 48.975 57.034 42.787 42.787 57.034 48.975 64 19"
                    stroke-dasharray="93 279"
                    className="star__stroke star__stroke--3"
                  ></polygon>
                  <polygon
                    points="64 4 73.287 43.966 92.284 35.716 84.034 54.713 124 64 84.034 73.287 92.284 92.284 73.287 84.034 64 124 54.713 84.034 35.716 92.284 43.966 73.287 4 64 43.966 54.713 35.716 35.716 54.713 43.966 64 4"
                    stroke-dasharray="124 372"
                    className="star__stroke star__stroke--4"
                  ></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div> */}

        <TreeLoader/>

      <div className="loader-words">
        <p>Carregando</p>
        <div className="words">
          <span className="word">Botões</span>
          <span className="word">Formularios</span>
          <span className="word">Gráficos</span>
          <span className="word">Dados</span>
          <span className="word">Atualizações</span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
