/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import { signInRequest } from 'src/store/modules/auth/actions';
import LogoDrEstagio from '../../assets/Images/Logo.svg';
import '../Login/style.css'
import { Image } from 'react-bootstrap';

const LoginParceiros: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      cpf: '',
      password: '',
    }
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        await dispatch(signInRequest(data.cpf, data.password));
      } catch (error) {
        alert("Crendeciais incorretas")
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  return (
    <>
      <section className='h-100vh w-full login-bg'>
        <div className="login__container">
          <div className="login__content">
          
            <Form onSubmit={handleSubmit(onSubmit)} className="login__form">
              <Image className='text-center login__img' style={{ margin: '0 auto', filter: 'brightness(10)' }} src={LogoDrEstagio} />
              <div>
                <h1 className="login__title">
                  <span>Bem vindo</span> ao Estagius.
                </h1>
                <p className="login__description">
                  Faça o login para continuar.
                </p>
              </div>

              <div>
                <div className="login__inputs">
                  <div>
                    <Controller
                      as={InputMask}
                      control={control}
                      mask="cnpj"
                      name="cpf"
                      label="CNPJ"
                      md="12"
                      required
                      className='login__input'
                    />
                  </div>

                  <div>
                    <div className="login__box">
                      <Controller
                        as={Input}
                        control={control}
                        type="password"
                        name="password"
                        label="Senha"
                        md="12"
                        required
                        className='login__input'
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="login__buttons">
                  <Button
                    className="login__button"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? 'Carregando...' : 'Logar'}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginParceiros;