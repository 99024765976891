import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

// Importações do bootstrap
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Input from '../../../components/Input';

import schema from './schema';
import api from 'src/services/axios';

import { toast } from 'react-toastify';
import InputDate from '../../../components/InputDate';
import { parseISO } from 'date-fns';

const ContactForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const params = useParams();

  const { handleSubmit, control, errors, setValue } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      company_name: '',
      name: '',
      date_birth: undefined,
      main_contact: '',
      secondary_contact: '',
      email: '',
      sector: '',
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    const contact = {
      company_name: data.company_name,
      name: data.name,
      date_birth: data.date_birth,
      main_contact: data.main_contact,
      secondary_contact: data.secondary_contact,
      email: data.email,
      sector: data.sector,
    };

    if (params.id) {
      api
        .put(`/contacts/${params.id}`, contact)
        .then(() => {
          history.goBack();
          toast.success('Atualizado com sucesso!');
        })
        .catch(() => {
          toast.error('Houve um erro ao atulizar o contato!');
        });
    } else {
      api
        .post('/contacts', contact)
        .then(() => {
          history.goBack();
          toast.success('Cadastrado com sucesso!');
        })
        .catch(() => {
          toast.error('Houve um erro ao cadastrar o contato!');
        });
    }
  };

  useEffect(() => {
    if (params.id) {
      api.get(`/contacts/${params.id}`).then((response) => {
        setValue('name', response.data.name);
        setValue('company_name', response.data.company_name);
        setValue(
          'date_birth',
          response.data.date_birth
            ? (parseISO(response.data.date_birth.substring(0, 10)) as any)
            : null
        );
        setValue('main_contact', response.data.main_contact);
        setValue('secondary_contact', response.data.secondary_contact);
        setValue('email', response.data.email);
        setValue('sector', response.data.sector);
      });
    }
  }, [params.id]);

  return (
    <Container fluid>
      <Card
        style={{
          background: 'var(--bg-secondary)',
        }}
      >
        <Card.Header
          as='h5'
          style={{
            background: 'transparent',
          }}
        >
          Novo contato
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body
            style={{
              background: 'var(--bg-default)',
              borderRadius: '10px',
              margin: '0.5rem 1rem',
            }}
          >
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name='company_name'
                label='Nome da empresa'
                md='4'
                errorMessage={errors.company_name?.message}
                required
              />
              <Controller
                as={Input}
                control={control}
                name='name'
                label='Nome'
                md='4'
                errorMessage={errors.name?.message}
                required
              />
              <Controller
                as={InputDate}
                control={control}
                name='date_birth'
                label='Data de nascimento'
                md='4'
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name='main_contact'
                label='1º Contato'
                md='4'
                errorMessage={errors.main_contact?.message}
                required
              />
              <Controller
                as={Input}
                control={control}
                name='secondary_contact'
                label='2º Contato'
                md='4'
              />

              <Controller
                as={Input}
                control={control}
                name='sector'
                label='Setor'
                md='4'
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name='email'
                label='Email'
                md='12'
              />
            </Form.Row>
          </Card.Body>
          <Card.Footer className='bg-default'>
            <Form.Row>
              <Button
                className='mr-3 button-primary'
                type='submit'
                disabled={loading}
                variant='success'
              >
                {loading ? 'Carregando...' : 'Salvar'}
              </Button>
              <Button onClick={() => history.goBack()} variant='danger'>
                Cancelar
              </Button>
            </Form.Row>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
};

export default ContactForm;
