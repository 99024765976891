import React, { useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { format, parseISO } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { handleGenerateReportAttendanceCount } from "../../Relatorios/templates/report";

import ExportJsonExcel from "js-export-excel";

// Componentes do react-bootrap
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

// Componentes da aplicação
import Select from "../../../components/Select";
import InputDate from "../../../components/InputDate";
import ComplexTable from "../../../components/ComplexTable";
import { LoadingIndicator } from "../../../components/LoadingIndicator"; // Adiciona o componente de loading

import api from "src/services/axios";
import { toast } from "react-toastify";

const PresencasContadorEmpresa: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false); // Estado de carregamento

  const { handleSubmit, control } = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {
      date_start: undefined,
      date_end: undefined,
      typeOptions: {
        value: "",
        label: "Tudo",
      },
    },
  });

  const OnSubmit = useCallback((data) => {
    setLoading(true); // Inicia o estado de carregamento ao submeter
    let queryParams = new URLSearchParams();

    if (!data.date_start && !data.date_end) {
      toast.error("Selecione uma faixa de datas");
      setLoading(false); // Finaliza o loading em caso de erro
      return;
    }

    queryParams.set("date_start", format(data.date_start, "yyyy-MM-dd"));
    queryParams.set("date_end", format(data.date_end, "yyyy-MM-dd"));
    queryParams.set("type", data.typeOptions.value);

    api
      .get(`/reports/companies/attendances/count?${queryParams}`)
      .then((response) => {
        setData(
          response.data.map((item) => {
            return {
              id: uuidv4(),
              cpf: item.cpf,
              company_name: item.company_name,
              type: item.type,
              trainee_name: item.name,
              primary_phone_contact: item.primary_phone_contact,
              count: item.count,
              date: format(
                parseISO(item.start_validity.substring(0, 10)),
                "dd/MM/yyyy"
              ),
            };
          })
        );
      })
      .finally(() => {
        setLoading(false); // Finaliza o loading após a requisição
      });
  }, []);

  const handleGenerateExcelFile = () => {
    const parseData = data.map((item) => {
      return {
        count: item.count,
        name: item.trainee_name,
        cpf: item.cpf,
        primary_phone_contact: item.primary_phone_contact,
        type: item.type,
      };
    });

    let option = {
      fileName: `${Date.now()}-presencas`,
      datas: [
        {
          sheetData: parseData,
          sheetName: "sheet",
          sheetFilter: ["count", "name", "cpf", "primary_phone_contact", "type"],
          sheetHeader: ["Qtd", "Nome", "Cpf", "Contato", "Tipo"],
        },
      ],
    };

    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  return (
    <Container fluid>
      <Card>
        <Card.Header as="h5">Relatório de presenças com contagem</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(OnSubmit)}>
            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name="date_start"
                label="Data inicial"
                md="6"
              />
              <Controller
                as={InputDate}
                control={control}
                name="date_end"
                label="Data final"
                md="6"
              />
            </Form.Row>

            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="typeOptions"
                label="Presença/Faltas"
                md="12"
                options={[
                  {
                    value: "",
                    label: "Tudo",
                  },
                  {
                    value: "PRESENÇA",
                    label: "Apenas presenças",
                  },
                  {
                    value: "FALTA",
                    label: "Apenas faltas",
                  },
                ]}
              />
            </Form.Row>
            <div className="d-flex justify-content-between">
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? "Buscando..." : "Filtrar"}
              </Button>
              <div className="d-flex" style={{ gap: "10px" }}>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => handleGenerateReportAttendanceCount(data, "")}
                  className="float-right"
                  disabled={loading}
                >
                  Gerar PDF
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => handleGenerateExcelFile()}
                  className="float-right"
                  disabled={loading}
                >
                  Gerar Excel
                </Button>
              </div>
            </div>
          </Form>

          {loading ? (
            <LoadingIndicator /> // Exibe o LoadingIndicator enquanto os dados estão sendo carregados
          ) : (
            <ComplexTable
              keyField="id"
              data={data}
              columns={[
                {
                  dataField: "company_name",
                  text: "Empresa",
                  sort: false,
                  filter: false,
                },
                {
                  dataField: "date",
                  text: "Data do contrato",
                  sort: true,
                  filter: false,
                },
                {
                  dataField: "trainee_name",
                  text: "Estagiário",
                  sort: true,
                  filter: false,
                },
                {
                  dataField: "cpf",
                  text: "CPF",
                  sort: true,
                  filter: false,
                },
                {
                  dataField: "primary_phone_contact",
                  text: "Telefone",
                  sort: true,
                  filter: false,
                },
                {
                  dataField: "type",
                  text: "Presença/Falta",
                  sort: false,
                  filter: false,
                },
                {
                  dataField: "count",
                  text: "Quantidade",
                  sort: true,
                  filter: true,
                },
              ]}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PresencasContadorEmpresa;
