import React from 'react';
import '../css/Snow.css'; // Importa o CSS para as animações

export const Snow = () => {
  const snowflakes = Array.from({ length: 200 }); // Array com 200 flocos de neve

  return (
    <>
      {snowflakes.map((_, i) => (
        <div key={i} className="snowflake"></div>
      ))}
    </>
  );
};
