import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from "../../../components/Sidebar";
import { Footer } from "../../../components/Footer";
import MobileWarningModal from "../../../components/MobileWarningModal/MobileWarningModal";
import { UpdateModal } from "../../../components/UpdatePopups";
import { Header } from "../../../components/Header";
import QuickActions from "../../../components/QuickActions";

function DefaultLayout({ children }) {
  return (
    <>
    <QuickActions/>
      <MobileWarningModal />
      <UpdateModal />
      <div className="d-flex">
      <Sidebar />
      <div className="d-flex flex-column w-100">
        <Header/>
      <div style={{ minHeight: "100vh",marginTop: '3rem' }}>
        {children}
        </div>
      <Footer />
      </div>
      </div>
    </>
  );
}

export default DefaultLayout;
