import { Footer } from "../../../components/Footer/index";
import React from "react";

export default function AuthLayout({ children }) {
  return (
    <>
      {children}
      <Footer />
    </>
  );
}
