import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  RiCalendarFill,
  RiBuildingFill,
  RiFileListFill,
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiMoneyDollarCircleFill,
  RiBookFill,
  RiAwardFill,
} from "react-icons/ri";

import "./style.css";
import { FaChartPie } from "react-icons/fa";
import { IconProfile } from "../IconProfile";

const Sidebar: React.FC = () => {
  const profile = useSelector((state) => state.user.profile);
  const location = useLocation();

  const [activeAccordion, setActiveAccordion] = useState<string | null>(null); // Controle do acordeão ativo

  const toggleAccordion = (section: string) => {
    // Se o acordeão clicado já está ativo, feche-o. Caso contrário, abra o acordeão e feche todos os outros.
    setActiveAccordion((prevSection) => (prevSection === section ? null : section));
  };

  return (
    <>
      <nav className="sidebar" id="sidebar">
        <div className="sidebar__container">
          <div className="sidebar__user">
            <div className="sidebar__img">
              <IconProfile />
            </div>

            <div className="sidebar__info">
              <h3>{profile.name}</h3>
              <span className="financial-access">Colaborador</span>
            </div>
          </div>

          <div className="sidebar__content">
            <div>
              <h3 className="sidebar__title">GERENCIAMENTO</h3>
              <div className="sidebar__list">
                <Link
                  to="/dashboard"
                  className={`sidebar__link ${
                    location.pathname === "/dashboard" ? "active-link" : ""
                  }`}
                >
                  <FaChartPie />
                  <span>Painel de controle</span>
                </Link>

                <Link
                  to="/agenda"
                  className={`sidebar__link ${
                    location.pathname === "/agenda" ? "active-link" : ""
                  }`}
                >
                  <RiCalendarFill />
                  <span>Agenda</span>
                </Link>

                {profile.financial_access && (
                  <Link
                    to="/relatorios/financeiro"
                    className={`sidebar__link ${
                      location.pathname === "/relatorios/financeiro"
                        ? "active-link"
                        : ""
                    }`}
                  >
                    <RiMoneyDollarCircleFill />
                    <span>Financeiro</span>
                  </Link>
                )}

                <Link
                  to="/cursos"
                  className={`sidebar__link ${
                    location.pathname === "/cursos" ? "active-link" : ""
                  }`}
                >
                  <RiBookFill />
                  <span>Cursos</span>
                </Link>

                {/* Acordeão para Cadastros */}
                <div className="sidebar__accordion">
                  <button
                    className="accordion-toggle"
                    onClick={() => toggleAccordion("cadastros")}
                  >
                    <RiBuildingFill />
                    <span>Cadastros</span>
                    {activeAccordion === "cadastros" ? (
                      <RiArrowDownSLine />
                    ) : (
                      <RiArrowRightSLine />
                    )}
                  </button>
                  {activeAccordion === "cadastros" && (
                    <div className="accordion-content">
                      <Link
                        to="/empresas"
                        className={`sidebar__sublink ${
                          location.pathname === "/empresas" ? "active-link" : ""
                        }`}
                      >
                        Empresas
                      </Link>
                      <Link
                        to="/estagiarios"
                        className={`sidebar__sublink ${
                          location.pathname === "/estagiarios"
                            ? "active-link"
                            : ""
                        }`}
                      >
                        Estagiários
                      </Link>
                      <Link
                        to="/instituicao_educacao"
                        className={`sidebar__sublink ${
                          location.pathname === "/instituicao_educacao"
                            ? "active-link"
                            : ""
                        }`}
                      >
                        Instituição de educação
                      </Link>
                      <Link
                        to="/grupos"
                        className={`sidebar__sublink ${
                          location.pathname === "/grupos" ? "active-link" : ""
                        }`}
                      >
                        Grupos
                      </Link>
                      <Link
                        to="/contatos"
                        className={`sidebar__sublink ${
                          location.pathname === "/contatos" ? "active-link" : ""
                        }`}
                      >
                        Contatos
                      </Link>
                    </div>
                  )}
                </div>

                {/* Acordeão para Contratos */}
                <div className="sidebar__accordion">
                  <button
                    className="accordion-toggle"
                    onClick={() => toggleAccordion("contratos")}
                  >
                    <RiFileListFill />
                    <span>Contratos</span>
                    {activeAccordion === "contratos" ? (
                      <RiArrowDownSLine />
                    ) : (
                      <RiArrowRightSLine />
                    )}
                  </button>
                  {activeAccordion === "contratos" && (
                    <div className="accordion-content">
                      <Link
                        to="/contratos"
                        className={`sidebar__sublink ${
                          location.pathname === "/contratos"
                            ? "active-link"
                            : ""
                        }`}
                      >
                        Contratos
                      </Link>
                      <Link
                        to="/relatorios/contratos_ativos"
                        className={`sidebar__sublink ${
                          location.pathname === "/relatorios/contratos_ativos"
                            ? "active-link"
                            : ""
                        }`}
                      >
                        Contratos Ativos
                      </Link>
                      <Link
                        to="/relatorios/contratos_desligados"
                        className={`sidebar__sublink ${
                          location.pathname ===
                          "/relatorios/contratos_desligados"
                            ? "active-link"
                            : ""
                        }`}
                      >
                        Contratos Desligados
                      </Link>
                      <Link
                        to="/relatorios/contratos_expirados"
                        className={`sidebar__sublink ${
                          location.pathname ===
                          "/relatorios/contratos_expirados"
                            ? "active-link"
                            : ""
                        }`}
                      >
                        Contratos Expirados
                      </Link>
                    </div>
                  )}
                </div>

                {/* Acordeão para Pedagógico */}
                <div className="sidebar__accordion">
                  <button
                    className="accordion-toggle"
                    onClick={() => toggleAccordion("pedagogico")}
                  >
                    <RiAwardFill />
                    <span>Pedagógico</span>
                    {activeAccordion === "pedagogico" ? (
                      <RiArrowDownSLine />
                    ) : (
                      <RiArrowRightSLine />
                    )}
                  </button>
                  {activeAccordion === "pedagogico" && (
                    <div className="accordion-content">
                      <Link
                        to="/relatorios/presencas"
                        className={`sidebar__sublink ${
                          location.pathname === "/relatorios/presencas"
                            ? "active-link"
                            : ""
                        }`}
                      >
                        Relatório de presenças
                      </Link>
                      <Link
                        to="/relatorios/presencas/contagem"
                        className={`sidebar__sublink ${
                          location.pathname === "/relatorios/presencas/contagem"
                            ? "active-link"
                            : ""
                        }`}
                      >
                        Contagens de presenças
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
