import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// Importações do bootstrap
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

// Meus componentes
import ComplexTable from "../../../components/ComplexTable";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

//services
import api from "../../../services/axios";
import Icon from "../../../components/icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const GroupList: React.FC = () => {
  const history = useHistory();

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carregamento

  useEffect(() => {
    api
      .get("groups")
      .then((response) => {
        setGroups(response.data);
      })
      .finally(() => {
        setLoading(false); 
      });
  }, []);

  return (
    <Container fluid>
      <Card style={{ boxShadow: "var(--card-bs)" }}>
        <Card.Header
          as="h5"
          className="d-flex justify-content-between align-items-center color-secondary"
        >
          <div>
            Grupos
          </div>
          <Button
            variant="primary"
            onClick={() => history.push("/grupos/novo")}
            className="button-primary"
          >
            <i className="bi bi-plus-lg mr-2"></i>
            Novo grupo
          </Button>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <LoadingIndicator /> // Exibe o indicador de carregamento enquanto os dados não são carregados
          ) : (
            <ComplexTable
              data={groups}
              columns={[
                {
                  dataField: "name",
                  text: "Nome",
                  sort: true,
                  filter: true,
                },
                {
                  dataField: "id",
                  text: "Ações",
                  sort: false,
                  formatter: (_, row) => (
                    <>
                      <OverlayTrigger
                        key="edit"
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-right`}>
                            Editar opção de grupo
                          </Tooltip>
                        }
                      >
                        <Icon
                          icon="FaEdit"
                          className="color-secondary interaction"
                          size={25}
                          onClick={() => history.push(`grupos/${row.id}`)}
                        />
                      </OverlayTrigger>
                    </>
                  ),
                },
              ]}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default GroupList;
